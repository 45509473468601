@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Blinker:wght@400;600;700&family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
  letter-spacing: 0em;
  color: white;
  background: #000;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

span {
  color: #05ff00;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
}

/*  HEADER */
nav {
  padding: 11.5px 20px;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  background: none;
  font-family: Lato, "sans-serif";
  font-weight: 500;
  line-height: 20px;
  background: rgba(255, 255, 255, 0.212);
}

nav .container {
  display: flex;
  align-items: center;
  justify-content: center;
}

nav img {
  cursor: pointer;
  margin: 0 auto 0 0;
}

nav a {
  color: white;
  margin-right: 40px;
  font-size: 14px;
}

nav ul {
  flex-direction: row;
  margin: 0 auto 0 auto;
  display: flex;
}

nav img.toggler {
  display: none;
}

/* .dot-1{
} */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 2px;
  height: 2px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  margin-left: 20px;
  box-shadow: 9999px 0 0 -5px #fff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  /* position: relative; */
  /* left:50; */
  /* top: 0; */
  /* margin-right: 10px; */
  width: 2px;
  height: 2px;
  border-radius: 2px;
  background-color: #fff;
  color: #fff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #fff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #fff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #fff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #fff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #fff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #fff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #fff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #fff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #fff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #fff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #fff;
  }
}

.carousel-text {
  padding-right: 5px;
}

@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
    width: 50vw;
    display: none;
  }

  nav img.toggler {
    display: block;
  }

  nav .container {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  nav ul.active {
    display: block;
    width: 100vw;
    position: relative;
    z-index: 3;
  }

  nav ul.active li.nav-item {
    padding: 10px 0 15px 0;
  }
}

/* ABOUT US PAGE */
.AboutUs,
.blog-all-post-page,
.home-page-background,
.contact {
  padding-top: 74px !important;
}

@media (min-width: 144px) {
  .AboutUs {
    text-align: center;
    background: #090b20;
    position: relative;
    overflow-x: clip;
  }
  .BottomCities.BlueBG {
    background: #090b20;
  }
  .aboutUsHead,
  .aboutsUsAwards,
  .aboutUsPartners {
    padding: 30px 5vw;
    /* min-height: calc(100vh - 100px); */
  }
  .aboutUsHead {
    min-height: calc(100vh - 10%);
  }
  .AboutUs h1,
  .contact h1,
  .AboutUs span {
    font-size: 20px;
    font-weight: 700;
    line-height: 170%;
  }
  .AboutUs h1 {
    display: flex;
    align-items: baseline;
    justify-content: center;
  }
  .AboutUs span {
    flex-direction: row;
    align-items: center;
    font-size: 20px !important;
  }
  .AboutUs span {
    display: inline-block;
  }
  .aboutUsHead p {
    text-align: justify;
    font-size: 112.5%;
    font-weight: 300;
    line-height: 125%;
    margin: 25px 0 30px;
  }

  .aboutUsHead .logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  }

  .aboutUsHead .logos img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .aboutUsHead .right img {
    display: none;
  }

  .aboutsUsAwards .imgGrid,
  .aboutUsPartners .imgGrid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 0px;
    position: relative;
    z-index: 1;
  }

  .aboutsUsAwards img {
    width: 22%;
    margin: 0 0 20px;
  }

  .aboutUsPartners {
    margin: 20px 0;
  }

  .aboutUsPartners img {
    width: 22%;
    margin: 0 0 20px;
  }
}
@media (min-width: 400px) {
  .AboutUs h1,
  .contact h1,
  .AboutUs span {
    font-size: 24px;
    font-weight: 700;
    line-height: 170%;
  }
}
@media (min-width: 768px) {
  .aboutUsHead,
  .aboutsUsAwards,
  .aboutUsPartners {
    padding: 30px 60px 60px;
  }

  .AboutUs h1 {
    font-size: 225%;
    line-height: 4.8vw;
    display: flex;
    align-items: baseline;
    gap: 0.2em;
  }

  .AboutUs p {
    font-size: 150%;
    margin: 50px auto 40px;
    line-height: 155%;
  }

  .aboutsUsAwards img {
    width: 22%;
    margin: 20px 1%;
  }
  .AboutUs span {
    font-size: 36px !important;
    padding-bottom: 0 !important;
  }
  /* .aboutUsPartners .imgGrid {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .aboutUsPartners .imgGrid img {
    max-height: 300px;
    object-fit: contain;
    margin-bottom: 30px;
  } */
}

@media (min-width: 1400px) {
  .aboutUsHead .logos {
    display: none;
  }

  .aboutUsHead .right img {
    display: flex;
    max-width: 600px;
  }

  .aboutUsHead {
    display: flex;
    flex-wrap: wrap;
  }

  .aboutUsHead .left {
    width: 45%;
  }

  .aboutUsHead {
    align-items: center;
    justify-content: space-between;
  }

  .AboutUs p {
    font-size: 225%;
    line-height: 120%;
  }

  .AboutUs h1,
  .AboutUs span {
    font-size: 2.5vw !important;
  }

  .aboutUsHead .left p {
    text-align: left;
    font-size: 2rem;
  }
  .aboutUsPartners h1 {
    justify-content: flex-start;
  }
  .aboutsUsAwards img {
    width: 22% !important;
    margin: 20px 1%;
  }
  /* .aboutUsPartners .imgGrid {
    display: grid;
    grid-template-columns: auto auto auto auto;
  } */

  .aboutUsPartners .imgGrid img {
    max-height: 300px;
    object-fit: contain;
    margin-bottom: 30px;
    width: 23%;
  }
}

/* contact form */

.contactForm {
  display: block;
  border-radius: 20px;
  max-height: 1000px;
}

.contactForm.hidden {
  visibility: hidden;
}

@keyframes CaretBlink {
  0% {
    opacity: 0;
  }
}

@media (min-width: 144px) {
  .contact {
    padding: 0 14px;
    background: #090b20;
  }

  .contactForm {
    width: 100%;
    border: 4px solid #444444;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.09);
    background: #191919;
  }
  .contact .blinking-cursor {
    font-size: 18px !important;
  }
  .contactForm .top {
    background: #444444;
    display: flex;
    justify-content: space-between;
    border-radius: 20px 20px 0px 0px;
    padding: 20px 15px;
    transform: translate(0px, -2px);
    align-items: center;
  }

  .contact h1 {
    padding: 25px 0px 40px;
    align-items: baseline;
  }

  .contactForm .top button {
    color: #c6c6c6;
    font-size: 36px;
  }

  .contact p {
    font-family: "Roboto", sans-serif;
    font-size: 3.5vw;
    font-weight: 400;
  }

  .contact .top p {
    color: #ababab;
  }

  .contactForm .body {
    padding: 10% 3%;
    background: #090b20;
    border-radius: 20px;
  }

  .contactForm .inputBox {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  .contactForm .inputBox label {
    font-size: 3.2vw;
    margin-bottom: 15px;
    margin-top: 25px;
  }

  .contactForm .inputBox span {
    font-size: 3.2vw;
    margin-right: 6px;
  }

  .contactForm .inputBox p {
    font-size: 3.5vw;
  }
  .contactForm .inputBox input,
  .contactForm .inputBox textarea {
    background: #2c2c2c;
    border-radius: 10px;
    padding: 13px 20px;
    color: #e2e2e2;
    border: none;
    font-family: "Roboto", sans-serif;
  }

  .contactForm .inputBox input:active {
    border: none;
    outline: none;
  }

  p.hitEnter {
    font-family: Roboto;
    font-weight: 500;
    line-height: 16px;
    margin: 5% 0;
    display: flex;
  }

  p.hitEnter::after {
    content: "";
    width: 12px;
    height: 20px;
    background: #fff;
    display: inline-block;
    align-items: center;
    transform: translate(10px, -2px);
    animation: CaretBlink 1.2s steps(2) infinite;
  }
  p.blinkingText {
    font-size: 14px;
  }
}
@media (min-width: 144px) and (max-width: 767px) {
  .contactContainer h1.react-typewriter-text {
    font-size: 3vw !important;
  }
}
@media (min-width: 768px) {
  .contactForm .inputBox label,
  .contact p {
    font-size: 24px;
  }

  .contactForm .inputBox span {
    font-size: 2.2vw;
  }

  .contactForm .inputBox input,
  .contactForm .inputBox textarea {
    font-size: 16px;
  }

  .contactForm .inputBox input {
    width: 55%;
  }

  .contactForm .inputBox textarea {
    width: 70%;
  }

  p.hitEnter::after {
    width: 12px;
    height: 24px;
    transform: translate(10px, -5px);
  }
}

@media (min-width: 1400px) {
  .contactForm .body {
    padding: 35px 30px 50px;
  }
  .aboutUsHead,
  .aboutsUsAwards,
  .aboutUsPartners {
    min-height: calc(100vh - 100px);
  }
}

/* -------------------------dhairya ------------ */

span {
  color: #05ff00;
}

.home-page-background {
  /* background-size: cover; background-position: center center; background-repeat: no-repeat; background-attachment: fixed; */
  /* position: relative; */
  /* padding-top: 54px; */
  /* background-image: url('./assets/Home/Home-bg.svg'); */
  /* background-image: url('./assets/Home/Home-bg.svg'); */
  background-size: cover;
  background-color: #1c1747;
  /* padding-bottom: 200px; */
  /* position: relative; */
  position: relative;
  padding-top: 54px;
  padding-bottom: 150px;
  /* padding-top: 54px; */
  padding-bottom: 150px;
  background-repeat: no-repeat;
}

/* .home-page-background:after{
clip-path: ellipse(50% 60% at 50% 100%); content: "";
} */
.home-page-background h1 {
  font-weight: 300;
  font-size: 68px;
  text-align: center;
  line-height: 86px;
  color: #ffffff;
  font-family: "Lato", sans-serif;
}

.scroll-down-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  justify-content: center;
}

.scroll-down-text img {
  width: 31.63px;
  height: 84px;
}

.scroll-down-text h1 {
  font-weight: normal;
  font-size: 36px;
  line-height: 43px;
}

.home-page-background span {
  color: #05ff00;
}

.home-page-bg-satelite img {
  position: absolute;
  top: 200px;
  right: 15%;
}

.home-page-section-intro {
  width: 91%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  color: #fff;
  font-family: "Lato", sans-serif;
  /* margin-top: 150px;
  margin-bottom: 60px; */
  justify-content: space-between;
}

.home-page-section-intro-text {
  width: 44%;
}

.home-page-section-intro-text a {
  color: #fff;
  padding: 15px 0;
  font-size: 20px;
}

.home-page-section-intro span {
  color: #05ff00;
}

.home-page-section-intro h1 {
  font-weight: 300;
  font-size: 72px;
  padding-bottom: 15px;
  line-height: 86px;
}

.home-page-section-intro p {
  font-weight: 300;
  padding-top: 20px;
  font-size: 32px;
  line-height: 42px;
}

.home-page-section-intro-img {
  position: relative;
  width: 52%;
}

.home-page-section-intro-img img {
  width: 100%;
  position: relative;
  right: -7px;
}

.home-page-section-intro-img-bg img {
  position: absolute;
  left: -12px;
  z-index: -1;
  top: -74px;
  width: 89%;
}

.join-waitlist-card {
  color: #fff;
  font-family: "Lato", sans-serif;
  margin-top: 214px;
  background: #3a3a3a;
  background-position: bottom;
  background-image: url("./assets/Home/joincard-wholebg.svg");
  background-size: 90%;
  border-radius: 30px;
  width: 65%;
  margin: 0 auto;
  margin-top: 135px;
  padding-top: 51px;
  margin-bottom: 116px;
  background-repeat: no-repeat;
  position: relative;
}

.join-waitlist-card h2 {
  font-weight: 300;
  font-size: 36px;
  margin-bottom: 96px;
  line-height: 43px;
  text-align: center;
}

.join-waitlist-card p {
  font-weight: 300;
  margin-bottom: 52px;
  font-size: 18px;
  text-align: center;
  padding: 0 20%;
  line-height: 22px;
}

.join-waitlist-card h1 {
  font-weight: bold;
  font-size: 64px;
  text-align: center;
  margin-bottom: 48px;
  line-height: 77px;
}

.join-waitlist-card span {
  color: #05ff00;
}

.join-email-input {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.join-email-input input::placeholder {
  font-weight: 300;
  font-size: 24px;
  color: #fff;
  line-height: 29px;
  padding-left: 0px;
}

.join-email-input-success input {
  background-color: #afe1af;
}

.join-email-input input {
  font-size: 28px;
  padding-left: 10px;
  height: 86px;
  outline: none;
  position: relative;
  margin-bottom: 80px;
  background: #979797;
  border: none;
  position: relative;
  width: 100%;
  border-radius: 20px;
  padding-left: 22px;
  color: #fff;
}

.transmit-btn button {
  position: absolute;
  right: 11%;
  background: #000000;
  width: 207px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  height: 66px;
  margin-top: 9px;
  border-radius: 14px;
}

h1,
p,
h3 {
  color: #fff;
}

.why-choose-too-decimal-container {
  z-index: 3;
  background: #191919;
  width: 72%;
  margin-left: 24%;
  margin-bottom: 288px;
  font-family: "Lato", sans-serif;
  border: 8.02384px solid #444444;
  box-sizing: border-box;
  padding-left: 54px;
  padding-top: 33px;
  padding-bottom: 98px;
  padding-right: 5px;
  border-radius: 58.1693px;
}

.we-are-just-different-section img {
  height: 75px;
  position: absolute;
  padding-left: 10px;
}

.we-are-just-different-section h1 {
  font-weight: 900;
  position: relative;
  font-size: 64px;
  line-height: 75px;
  display: flex;
  align-items: center;
}

.color-dots {
  display: flex;
  margin-bottom: 50px;
}

.color-dots div {
  margin: 0 6px;
}

.color-dot1 {
  width: 15px;
  height: 16.46px;
  border-radius: 50%;
  background: #f24e1e;
}

.color-dot2 {
  width: 15px;
  height: 16.46px;
  border-radius: 50%;
  background: #ffc700;
}

.color-dot3 {
  width: 15px;
  height: 16.46px;
  border-radius: 50%;
  background: #0fa958;
}
.why-too-decimal-section-top-text {
  display: flex;
  flex-direction: column;
}
.why-too-decimal-section-top-text p,
.why-too-decimal-section-top-text span {
  display: inline-block;
  font-weight: normal;
  font-size: 18px;
  padding-bottom: 13px;
  line-height: 21px;
}

.we-are-just-different-section {
  margin-top: 35px;
}

.we-are-just-different-section p {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding-top: 26px;
}

.why-too-decimal-container-follow-lines {
  border-left: 1px solid #ffffff;
  padding-top: 20px;
  margin-top: 10px;
  padding-bottom: 20px;
}

.why-too-decimal-container-follow-lines img {
  height: unset;
  position: unset;
  padding: unset;
}

.why-too-decimal-container-follow-lines-heading {
  display: flex;
}

.why-too-decimal-container-follow-lines-heading h1 {
  font-weight: bold;
  font-size: 48px;
  padding-left: 8px;
  line-height: 56px;
}

.why-too-decimal-container-follow-lines p,
.why-too-decimal-container-follow-lines-heading-bottom-part-content {
  font-weight: 300;
  font-size: 26px;
  margin-left: 32px;
  padding-bottom: 35px;
  line-height: 30px;
}

.why-too-decimal-container-follow-lines-heading span {
  font-weight: 500;
  font-size: 18px;
  display: block;
  line-height: 21px;
}

.vertical-line {
  height: 55px;
  width: 1px;
  background-color: #fff;
  margin-left: 47px;
  margin-top: 13px;
}

.text-under-dir {
  padding-left: 45px;
}

.text-under-dir div {
  margin-top: -28px;
}

.why-too-decimal-container-follow-lines-heading img {
  height: unset;
  position: unset;
  padding: unset;
}

.why-too-decimal-container-follow-lines-heading {
  display: flex;
}

.why-too-decimal-container-follow-lines-heading-bottom-part {
  margin-top: -27px;
}

.why-too-decimal-container-follow-lines-heading-bottom-part-content {
  font-weight: 300 !important;
  font-size: 26px !important;
  margin-left: 32px;
  padding-bottom: 35px;
  line-height: 30px !important;
}

.why-too-decimal-container-bottom-text h3 {
  font-weight: 300;
  font-size: 26px;
  line-height: 30px;
  padding: 23px 0;
  width: 70%;
}

.why-too-decimal-container-bottom-text p {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

.bottom-why-text {
  padding-bottom: 46px;
}

.timeout-text {
  display: flex;
  align-items: center;
}

.timeout-text p {
  display: inline;
  padding-bottom: 2px;
  padding-left: 7px;
}

.industries-we-serve-section {
  width: 93%;
  background: #272727;
  padding-bottom: 434px;
  padding-top: 173px;
  border-radius: 30px;
  margin: 0 auto;
}

.industry-section-text {
  position: relative;
  margin-left: 15%;
  width: 60%;
}

.industries-we-serve-section p {
  font-family: "Amatic SC", cursive;
  font-weight: bold;
  font-size: 64px;
  line-height: 81px;
  top: -80px;
  right: 32%;
  transform: rotate(-16.64deg);
  position: absolute;
  color: #0094ff;
}

.industry-section-text h1 {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 72px;
  line-height: 86px;
}

.join-wait-list-card-bottom-container .join-waitlist-card {
  margin-bottom: 0;
  margin-top: -290px;
  margin-bottom: 20px;
}

.galaxy-bacgkground {
  position: relative;
}

.galaxy-bacgkground img {
  position: absolute;
  z-index: -1;
  top: 30px;
  right: 0;
}

.idustry-section-carosel {
  margin-top: 103px;
}

.carousel-card1 {
  background: #152c7c;
  border-radius: 20px;
}

.carousel-card2 {
  background: #ffa9a9;
  border-radius: 20px;
}

.carousel-card3 {
  background: #a9f0ff;
  border-radius: 20px;
}

.slick-carousel {
  width: 95% !important;
}

.slick-slide {
  margin: 0 50px;
}

.custom-slick-slider {
  margin-top: 50px;
}

/* the parent */
.slick-list {
  margin: 0 -50px;
}

.carousel-card {
  display: flex !important;
  justify-content: center;
  max-width: 660px;
  align-items: center;
  margin: 0 70px;
  padding: 50px 20px;
}

.carosel-card-img {
  position: relative;
  width: 68%;
}

.carosel-card-img img {
  z-index: 2;
  position: relative;
  width: 90%;
}

.carousel .slide img {
  width: 48vw !important;
}

.carousel-text p {
  font-style: normal;
  font-weight: 300;
  /* font-size: 36px; */
  line-height: 34px;
  top: unset;
  padding-bottom: 30px;
  font-family: Lato;
  right: unset;
  transform: unset;
  position: unset;
  color: #fff;
}

.bike-img-bg img {
  position: absolute;
  top: -25px;
  left: 0;
}

.carousel .slider-wrapper {
  padding: 50px 0;
}

.why-decimal-heading {
  font-weight: 300;

  font-size: 72px;
  font-family: Lato;
  margin-left: 9%;
  /* margin-bottom: 456px; */
  margin-bottom: 22%;
  line-height: 86px;
}

.why-too-decimal-section {
  position: relative;
}

.why-too-decimal-image img {
  position: absolute;
  left: -170px;
  top: 40px;
  z-index: -1;
}

.display-after-1200 {
  display: none;
}
/* .carousel-card{
	width: 90% !important;
} */

@media (max-width: 400px) {
  .carousel-card {
    width: 90% !important;
  }
}
@media (max-width: 1570px) {
  .home-page-section-intro h1 {
    font-size: 67px;
  }
  .home-page-section-intro p {
    font-size: 28px;
  }
}
@media (max-width: 1500px) {
  .home-page-section-intro p {
    font-size: 28px;
    line-height: 48px;
  }
  .home-page-section-intro-text {
    width: 48%;
  }
}
@media only screen and (max-width: 1200px) {
  .home-page-section-intro {
    justify-content: flex-end;
  }
  .join-waitlist-card {
    margin-top: 176px;
  }

  .join-email-input input {
    margin-bottom: 63px;
  }

  .home-page-background h1 {
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
  }

  .home-page-background h1 {
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
  }

  .scroll-down-text img {
    display: none;
  }

  .home-page-bg-satelite img {
    width: 176px;
  }

  .home-page-section-intro {
    flex-direction: column-reverse;
    margin-top: 40px;
  }

  .home-page-section-intro-text {
    width: unset;
    text-align: center;
  }

  .home-page-section-intro-img {
    width: unset;
    text-align: center;
    margin-bottom: 60px;
  }

  .join-waitlist-card {
    width: 90%;
    background-size: 63%;
    font-family: Lato;
  }

  .transmit-btn button {
    width: 169px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    right: 7%;
    margin-top: 7px;
    height: 54px;
  }

  .join-waitlist-card h1 {
    font-size: 36px;
    line-height: 43px;
  }

  .join-waitlist-card p {
    font-weight: 300;
    font-size: 12px;
    padding: 0 16%;
    line-height: 14px;
  }

  .join-email-input {
    width: 88%;
  }

  .join-email-input input {
    height: 68px;
    border-radius: 20px;
    font-size: 16px;
  }

  .join-waitlist-card h2 {
    font-size: 24px;
    margin-bottom: 43px;
    line-height: 29px;
  }

  .why-decimal-heading {
    font-weight: 300;
    font-size: 60px;
    line-height: 72px;
    margin-left: 0;
    text-align: center;
    margin-bottom: 50px;
  }

  .why-too-decimal-section-top-text p,
  .we-are-just-different-section p {
    font-size: 16px;
    line-height: 19px;
  }

  .we-are-just-different-section h1 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
  }
  .carousel-text h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
  }
  .we-are-just-different-section img {
    height: 40px;
    margin-top: 2px;
  }

  .why-too-decimal-container-follow-lines-heading img {
    height: unset;
  }

  .we-are-just-different-section h1 {
    font-size: 36px;
    line-height: 42px;
  }

  .why-too-decimal-container-follow-lines p {
    font-size: 18px;
    padding-bottom: 60px;
    line-height: 21px;
  }

  .text-under-dir div {
    margin-top: -22px;
  }

  .why-too-decimal-container-follow-lines-heading-bottom-part-content {
    font-size: 18px !important;
    line-height: 21px !important;
  }

  .why-choose-too-decimal-container {
    width: 88%;
    margin: 0 auto;
  }

  .industries-we-serve-section {
    margin-top: 136px;
    padding-bottom: 88px;
    padding-top: 68px;
  }

  .home-page-section-intro p {
    font-weight: 300;
    font-size: 28px;
    padding: 0 10%;
    text-align: justify;
    margin-top: 50px;
    line-height: 43px;
  }

  .home-page-section-intro h1 {
    font-weight: 300;
    font-size: 64px;
    line-height: 77px;
  }

  .join-wait-list-card-bottom-container .join-waitlist-card {
    margin-top: 55px;
  }

  .industry-section-text {
    width: unset;
    margin-left: unset;
    text-align: center;
  }

  .home-page-section-intro-img img,
  .home-page-section-intro-img-bg img {
    width: 67%;
  }

  .home-page-section-intro-img-bg img {
    width: 60%;
    top: 195px;
  }

  .home-page-section-intro-img-bg img {
    left: 90px;
  }

  .why-choose-too-decimal-container {
    padding-right: 60px;
    padding-bottom: 60px;
  }

  .why-too-decimal-container-bottom-text h3 {
    font-size: 18px;
    line-height: 21px;
  }

  .bottom-why-text {
    padding-bottom: 20px;
  }

  .we-are-just-different-section p {
    padding-top: 16px;
  }

  .industry-section-text h1 {
    font-size: 60px;
    line-height: 72px;
  }

  .industries-we-serve-section p {
    font-size: 40px;
    line-height: 50px;
    top: -33px;
    right: 27%;
  }

  .carousel-text p {
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
  }

  .carousel-text h1 {
    font-weight: normal;
    font-size: 24px;
    margin-top: 45px;
    line-height: 43px;
  }

  .why-too-decimal-container-follow-lines-heading-bottom-part {
    margin-top: -21px;
  }

  .why-too-decimal-image img {
    position: absolute;
    left: -55px;
    top: 91px;
    height: 1500px;
    z-index: -1;
  }

  .join-email-input input::placeholder {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    font-family: Lato;
    font-size: 16px;
  }

  /* .carousel .slide{min-width: 72% !important;} */
  .display-none-text,
  .display-none-sub {
    display: none;
  }

  .display-after-1200 {
    display: block;
  }

  .display-after-1200:nth-child(2) {
    margin-bottom: 90px;
    color: #fff;
    font-family: Lato;
    padding: 15px 0;
    font-size: 20px;
  }
  .contactContainer img.cmd {
    display: none;
  }
}

@media (max-width: 800px) {
  .home-page-bg-satelite img {
    top: 28%;
  }

  .home-page-background {
    padding-bottom: 81px;
  }
}

@media (max-width: 600px) {
  .home-page-section-intro-img-bg img {
    top: 260px;
  }
  .we-are-just-different-section h1 {
    font-size: 29px;
  }
}

/* @media (max-width: 549px) {
  .we-are-just-different-section h1 {
    font-size: 24px;
    margin-bottom: 42px;
    line-height: 28px;
  }
} */
@media (max-width: 550px) {
  /* .we-are-just-different-section h1{ } */
  .home-page-background h1 {
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
  }

  .home-page-background h1 {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
  }

  .home-page-bg-satelite img {
    width: 64px;
    top: 33%;
  }

  .home-page-section-intro h1 {
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
  }

  .display-after-1200:nth-child(2) {
    font-size: 14px;
    padding: 0;
  }

  .home-page-section-intro p {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
  }

  .join-waitlist-card h1 {
    font-size: 20px;
    line-height: 24px;
  }

  .join-waitlist-card p {
    padding: 0 6%;
    font-family: Lato;
  }

  .join-waitlist-card h2 {
    font-size: 20px;
    line-height: 24px;
    padding: 0 6%;
  }

  .join-waitlist-card {
    background-position: -16% 30px;
    width: 88%;
    background-size: 500px;
    padding-top: 30px;
  }

  .why-decimal-heading {
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
    padding: 0 4%;
  }

  .why-choose-too-decimal-container {
    width: 91%;
    border-radius: 30px;
    border: 5px solid #444444;
    padding-left: 36px;
    padding-top: 20px;
  }

  .color-dot1,
  .color-dot2,
  .color-dot3 {
    width: 10px;
    height: 11.46px;
  }

  .color-dot1 {
    margin-left: 0 !important;
  }

  .color-dots {
    margin-bottom: 17px;
  }

  .why-too-decimal-section-top-text p {
    padding-bottom: 20px;
  }

  .why-too-decimal-section-top-text p,
  .we-are-just-different-section p {
    font-size: 12px;
    line-height: 14px;
  }

  .why-too-decimal-container-follow-lines p {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
  }

  .we-are-just-different-section {
    margin-top: 5px;
  }

  .we-are-just-different-section h1 {
    font-size: 24px;
    margin-bottom: 36px;
    line-height: 28px;
  }
  h1.differentGIFText {
    margin-bottom: 0;
  }
  .we-are-just-different-section img {
    height: 26px;
    padding-left: 5px;
  }

  .why-too-decimal-container-follow-lines-heading img {
    height: unset;
    padding-left: 0;
  }

  .why-too-decimal-container-follow-lines-heading h1 {
    margin-bottom: 0;
  }

  .text-under-dir div {
    margin-top: -14px;
    margin-left: 2px;
  }

  .why-too-decimal-container-follow-lines-heading-bottom-part {
    margin-top: -14px;
  }

  .why-too-decimal-container-follow-lines-heading-bottom-part-content {
    font-weight: 300;
    font-size: 12px !important;
    line-height: 14px !important;
  }

  .bottom-why-text {
    margin-left: 31px;
  }

  .timeout-text {
    font-size: 12px;
    line-height: 14px;
  }

  .why-choose-too-decimal-container {
    padding-right: 10px;
    padding-bottom: 52px;
  }

  .why-too-decimal-container-bottom-text h3 {
    width: unset;
    font-size: 12px;
    line-height: 14px;
  }

  .why-too-decimal-container-bottom-text span {
    font-size: 12px;
  }

  .industry-section-text h1 {
    font-size: 36px;
    line-height: 43px;
  }

  .industries-we-serve-section p {
    font-size: 24px;
    line-height: 30px;
  }

  .industry-section-text img {
    width: 14px;
    height: 17px;
  }

  .industries-we-serve-section {
    padding-bottom: 10px;
  }

  .idustry-section-carosel {
    margin-top: 42px;
  }

  .industries-we-serve-section {
    width: 94%;
  }

  .home-page-section-intro p {
    margin-top: 0;
    padding: 0 5%;
  }

  .home-page-section-intro-img-bg img {
    width: 63%;
    margin-left: 0;
    left: 46px;
    top: 140px;
  }

  .join-waitlist-card {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  .first-join-wait-list {
    margin-bottom: 90px;
  }
  .transmit-btn button {
    width: auto;
    height: auto;
    padding: 20px 15px;
    font-size: 12px;
    line-height: 14px;
  }

  .timeout-text span {
    font-size: 12px;
  }

  .transmit-btn button {
    right: 8%;
  }

  .join-email-input input {
    margin-bottom: 42px;
  }

  .join-waitlist-card h1 {
    margin-bottom: 15px;
  }

  .join-waitlist-card p {
    margin-bottom: 20px;
  }

  .join-waitlist-card h2 {
    margin-bottom: 27px;
  }

  .home-page-section-intro {
    margin-top: 20px;
  }
}
@media (max-width: 400px) {
  .why-too-decimal-container-follow-lines-heading h1 {
    font-size: 20px;
  }
}
.home-page-background {
  /* background-image: url('../../assets/Home/Home-bg.svg'); */
  /* background-size: contain; */
  /* padding-bottom: 200px; */
  /* background-repeat: no-repeat;
  position: relative;
  width: 85%;
  height: 610px; */
  /* background-image: ; */
  /* background-size: cover; background-position: center center; background-repeat: no-repeat; background-attachment: fixed; */
  /* position: relative; */
  /* padding-top: 54px; */
  /* background-image: url('./assets/Home/Home-bg.svg'); */
  background-size: cover;
  background-color: #1c1747;
  /* padding-bottom: 200px; */
  /* position: relative; */
  position: relative;
  /* padding-top: 54px; */
  padding-bottom: 150px;
  background-repeat: no-repeat;
}

@media (max-width: 400px) {
  .text-under-dir div {
    margin-top: -15px;
  }
}

/* -------------blog-landing------------------ */

.blog-landing-page-header {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  /* align-items: center;  */
  padding-top: 117px;
}

.blog-landing-page-header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 0 15%;
  background-size: 100%;
}

.blog-landing-page-header-input {
  /* width: 707px; */
  width: 38%;
  margin: 0 auto;
  position: relative;
}

.blog-landing-page-header-input img {
  width: 25.76px;
  height: 25.76px;
  z-index: 2;
  right: 15px;
  position: absolute;
}

.blog-landing-page-header-input input {
  width: 100%;
  height: auto;
  padding: 20px 0 20px 20px;
  background: #303030;
  border-radius: 10px;
  border: none;
}

/* ..blog-landing-page-header-input input::placeholder{padding-left: 20px;} */
.blog-landing-feature-section {
  margin-left: 104px;
  margin-right: 80px;
}

.blog-landing-feature-section1 {
  display: flex;
  justify-content: space-around;
  padding-bottom: 230px;
}

.blog-landing-feature-section1-info {
  margin-left: 120px;
}

.blog-landing-page-header-input {
  /* margin-left: 30%; */
  margin-bottom: 50px;
  position: relative;
  display: flex;
  align-items: center;
}

.margin-0-info {
  margin-left: 0;
}

.margin-left-blog-img {
  margin-left: 209px;
}

.blog-landing-feature-section1img {
  width: 45%;
}

.blog-landing-feature-section1img img {
  width: 100%;
}

.blog-landing-feature-section1-info {
  width: 38%;
}

.blog-landing-semi-nav ul li a {
  padding: 0 25px;
  font-size: 24px;
  text-decoration: none;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  padding: 0 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
}

.blog-landing-semi-nav ul {
  display: flex;
  padding-left: 0rem;
  justify-content: center;
}

.blog-landing-semi-nav ul li {
  list-style: none;
  display: inline;
}

.blog-landing-semi-nav ul li:last-child {
  padding-top: 4px;
  padding-left: 10px;
}

.blog-landing-semi-nav ul li a:hover {
  width: 86px;
  color: rgba(234, 34, 122, 1);
  height: 45px;
  border: 1px solid #ea227a;
  border-radius: 37px;
}

.blog-landing-card1 {
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  /* border-radius: 5px 5px 20px 20px;  */
  border-radius: 20px;
  width: 23%;
}

.blog-landing-page-row1 {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-around;
  align-items: center;
}

.blog-landing-page-row2 {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-around;
  align-items: center;
  margin-top: 52px;
}

.blog-landing-card1 img {
  width: 100%;
  /* border-radius: 20px 20px 0px 0px; */
}

.blog-landing-page-recent {
  background-color: #090b20;
  margin-bottom: 50px;
}

.recent-post-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recent-post-btn button {
  width: 173px;
  height: 50px;
  border: none;
  margin-top: 65px;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  /* background-color: transparent;  */
  /* background-image: linear-gradient(#0e0e0e, #0e0e0e), */
  /* linear-gradient(to right, #3e003b, #013146); */
  /* border:2px solid linear-gradient(97.33deg, #FF008A 4.87%, #0085FF 137.06%);  */
  background: none;
  border-radius: 10px;
  background-image: linear-gradient(
      81.71deg,
      rgba(11, 11, 11, 0.8) 0%,
      rgba(45, 45, 45, 0.8) 0.01%,
      rgba(21, 21, 21, 0.8) 99.13%
    ),
    linear-gradient(to right, #3e003b 60%, #013146);

  border: double 3px transparent;
  background-clip: content-box, border-box;
  background-origin: border-box;
}

.blog-landing-page-header-text h1 {
  font-weight: bold;
  font-family: poppins;
  font-size: 48px;
  line-height: 62px;
  margin-bottom: 50px;
}

.blog-landing-page-header-text p {
  font-family: poppins;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  margin-bottom: 75px;
}

.blog-landing-feature-section h1 {
  margin-bottom: 70px;
}

.blog-landing-feature-section1-info a {
  background: #ffcbe2;
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  font-family: Poppins;
  color: #ea227a;
  padding: 5px 11px;
  text-decoration: none;
  margin-bottom: 12px;
}

.blog-landing-feature-section1-info h2 {
  font-weight: bold;
  font-family: Poppins;
  font-size: 34px;
  padding-top: 17px;
  line-height: 54px;
}

.blog-landing-feature-section1-info p {
  font-size: 20px;
  line-height: 33px;
  font-family: Poppins;
  color: #333333;
}

.blog-landing-feature-section1-info-ptag {
  margin-top: 20px;
}

.blog-landing-clock {
  display: flex;
}

.blog-landing-clock p {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 0rem;
  margin-left: 10px;
}

.blog-landing-feature-section1-info button {
  width: 173px;
  height: 50px;
  border: none;
  font-size: 18px;
  line-height: 23px;
  margin-top: 16px;
  color: #ffffff;
  background: #ff9447;
  border-radius: 10px;
}

.blog-landing-card1 a {
  text-decoration: none;
  color: #fa7616;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}

.blog-landing-page-recent h1 {
  font-family: Poppins;
  font-size: 48px;
  line-height: 62px;
  margin: 54px 0 48px 0px;
}
.blog-all-post-page h1 {
  position: relative;
  z-index: 2;
}
.blog-landing-semi-nav {
  margin-bottom: 83px;
}

.blog-landing-page-row1-group1 {
  display: flex;
  justify-content: space-around;
}

.blog-landing-page-row1-group2 {
  justify-content: space-around;
  display: flex;
}

.blog-landing-page-row2-group2 {
  justify-content: space-around;
  display: flex;
}

.blog-landing-page-row2-group1 {
  justify-content: space-around;
  display: flex;
}

.blog-landing-card1 {
  width: 45%;
}

.blog-landing-feature-section1img img {
  z-index: 1;
}

.blog-landing-category-btn {
  display: none;
}

.blog-landing-page-header {
  font-family: "DM Sans";
  background-size: contain;
}

.blog-landing-feature-section1-info-16-august {
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}

.blog-landing-feature-section h1 {
  font-weight: bold;
  font-size: 48px;
  line-height: 62px;
  font-family: Poppins;
}

.blog-open-card-info-namedate {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.blog-open-card-info-namedate p {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  /* 600 */

  color: #718096;
}

.blog-open-card-info {
  /* margin-left: 13px;
  margin-top: 22px;
  margin-right: 28px;
  margin-bottom: 13px;
  Check this change here
  */
  margin: 22px 28px 13px 13px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-open-card-info h3 {
  font-weight: bold;
  font-size: 22px;
  line-height: 31px;
  font-family: Poppins;
  color: #2d3748;
}

.blog-landing-pink-grad {
  position: absolute;
  top: 3%;
  z-index: -1;
}

.blog-landing-pink-grad2 {
  position: absolute;
  z-index: -1;
  top: 33%;
  right: -15%;
  transform: rotate(51.57deg);
}

.blog-landing-weekly-head-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 50px;
}

.blog-weekly-head-card1 h1 {
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
}

.blog-weekly-head-card1 p {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
}

.blog-weekly-head-card1 {
  background-size: cover;
  /* padding: 20rem 20px 0;max-width: 69ch; */
  width: 35%;
  /* width: 40%;padding-top: 360px; */
  padding-top: 21%;
  padding-bottom: 20px;
  padding-left: 28px;
  padding-right: 23px;
  border-radius: 20px;
}

.blog-landing-feature-section1-info p {
  color: #fff;
}

.blog-landing-clock {
  display: none;
}

.blog-landing-feature-section1-info p {
  margin-bottom: 15px;
}

.blog-landing-feature-section1-info-ptag {
  margin-top: 5px;
}

.blog-landing-feature-section1-info h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

@media only screen and (max-width: 1550px) {
  .blog-landing-feature-section1img {
    width: 50%;
    margin-top: 45px;
  }

  .margin-left-blog-img {
    margin-left: 150px;
  }
}

@media only screen and (max-width: 1010px) {
  .blog-landing-page-row2 {
    margin-top: 75px;
  }

  .blog-landing-weekly-head-section {
    flex-direction: column;
    justify-content: center;
  }

  .blog-weekly-head-card1 {
    width: 70%;
    padding-top: 330px;
  }

  .blog-weekly-head-card1 h1 {
    font-size: 24px;
    line-height: 36px;
  }

  .blog-weekly-head-card2 {
    margin-top: 50px;
  }

  .margin-left-blog-img {
    margin-left: 93px;
  }

  .blog-landing-feature-section1img {
    width: 60%;
  }

  .blog-landing-feature-section1-info {
    margin-left: 80px;
  }

  .blog-landing-feature-section {
    margin-left: 70px;
    margin-right: 40px;
  }

  .margin-0-info {
    margin-left: 0;
  }

  .blog-landing-feature-section1img {
    margin-top: 107px;
  }

  .blog-landing-feature-section1 {
    padding-bottom: 140px;
  }
}

@media only screen and (max-width: 1400px) {
  .blog-landing-semi-nav ul li a {
    padding: 0 15px;
  }
  .contactLeft,
  .line1,
  .contact h1 > img {
    display: none;
  }
  .contact h1 {
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) {
  .vanish-nav-after-850 {
    display: none;
  }

  .blog-landing-page-row1 {
    flex-direction: column;
  }

  .blog-landing-card-vanish-after-850 {
    display: none;
  }

  .blog-landing-page-row1-group2 {
    margin-top: 75px;
  }
}

@media only screen and (max-width: 890px) {
  .blog-landing-semi-nav ul li a:hover {
    background-color: unset;
    width: unset;
    color: #fa7616;
    height: unset;
    border: 0;
    border-radius: 0;
  }

  .blog-landing-page-header {
    padding-top: 87px;
  }

  .blog-landing-page-header-input {
    margin-bottom: 30px;
  }

  .blog-landing-feature-section h1 {
    font-size: 36px;
    line-height: 47px;
  }

  .blog-landing-feature-section1img {
    margin-top: 0px;
  }

  .blog-landing-feature-section1img img {
    border-radius: 20px;
  }

  .blog-landing-semi-nav ul li a {
    padding: 0 13px;
  }

  .blog-landing-semi-nav ul li a {
    font-size: 18px;
    line-height: 23px;
  }

  .blog-landing-feature-section1 {
    flex-direction: column;
    padding-bottom: 55fpx;
  }

  .feauture-section-row-reverse {
    flex-direction: column-reverse;
  }

  .margin-left-blog-img {
    margin-left: 0;
  }

  .blog-landing-feature-section1-info {
    margin-left: 0;
  }

  .blog-landing-feature-section1img {
    width: 95%;
  }

  .blog-landing-feature-section1-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 96%;
  }

  .blog-landing-page-header-input {
    width: 53%;
    /* margin-left: 22%; */
  }

  .blog-landing-page-row1 {
    flex-direction: column;
  }

  .blog-landing-card-vanish-after-850 {
    display: none;
  }

  .blog-landing-page-row1-group2 {
    margin-top: 75px;
  }

  .blog-landing-page-header-text h1 {
    font-size: 36px;
    line-height: 47px;
  }

  .blog-landing-page-header-text p {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 25px;
  }

  .blog-landing-feature-section h1 {
    text-align: center;
  }

  .blog-landing-feature-section1-info h2 {
    font-size: 24px;
    line-height: 31px;
    margin-top: 25px;
    margin-bottom: 30px;
    text-align: center;
  }

  .blog-landing-feature-section1-info p {
    font-size: 18px;
    line-height: 23px;
    text-align: center;
  }

  .blog-landing-feature-section1-info button {
    margin-top: 30px;
  }

  .blog-landing-page-recent h1 {
    font-size: 36px;
    line-height: 47px;
    margin: 44px 0 40px 30px;
  }

  .blog-landing-semi-nav {
    margin-bottom: 50px;
  }

  .blog-landing-page-recent {
    margin-bottom: 100px;
  }

  .blog-landing-feature-section h1 {
    margin-bottom: 50px;
  }

  .blog-landing-semi-nav ul li:last-child {
    display: none;
  }

  .blog-landing-page-header-input {
    width: 65%;
  }
}

@media only screen and (max-width: 676px) {
  .blog-landing-semi-nav ul li a {
    padding: 0 8px;
  }
}

@media only screen and (max-width: 620px) {
  .blog-landing-semi-nav {
    display: none;
  }

  .blog-weekly-head-card1 {
    padding-top: 300px;
  }
}

@media only screen and (max-width: 520px) {
  .blog-weekly-head-card1 {
    width: 80%;
    padding-top: 160px;
  }

  .blog-weekly-head-card1 h1 {
    font-size: 18px;
    line-height: 27px;
  }

  .blog-weekly-head-card2 {
    margin-top: 20px;
  }

  .blog-landing-page-row1-group2 {
    margin-top: 45px;
  }

  .blog-landing-page-row2-group1 {
    display: none;
  }

  .blog-landing-page-row1-group1 {
    flex-direction: column;
  }

  .blog-landing-page-row1-group2 {
    flex-direction: column;
  }

  .blog-landing-card1 {
    width: 100%;
  }

  .margin-top-landing-card1 {
    margin-top: 45px;
  }

  .blog-landing-feature-section {
    margin-left: 37px;
    margin-right: 19px;
  }

  .blog-landing-page-row1 {
    width: 80%;
  }

  .blog-landing-page-header-text h1 {
    font-size: 24px;
    line-height: 31px;
  }

  .blog-landing-page-header-input {
    width: 62%;
    margin: unset;
    margin-left: 13%;
    /* margin-left: 8%; */
    margin-bottom: 45px;
  }

  .blog-landing-page-header-text {
    width: 90%;
  }

  .blog-landing-feature-section1-info h2 {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0px;
  }

  .blog-landing-feature-section1-info h3 {
    font-size: 14px;
    line-height: 18px;
  }

  .blog-landing-feature-section1-info p {
    font-size: 16px;
    line-height: 21px;
  }

  .blog-landing-feature-section1-info button {
    width: 113px;
    height: 42px;
    font-size: 14px;
    line-height: 18px;
    margin-top: 14px;
  }

  .blog-landing-card1 a {
    font-size: 16px;
    line-height: 21px;
  }

  .recent-post-btn button {
    margin-bottom: 30px;
    margin-top: 40px;
    width: 133px;
    height: 42px;
    font-size: 14px;
    line-height: 18px;
  }

  .blog-landing-feature-section1img img {
    z-index: 1;
  }

  .blog-landing-category-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .blog-landing-category-btn img {
    position: absolute;
    left: 56%;
  }

  .blog-landing-category-btn a {
    font-size: 14px;
    text-decoration: none;
    line-height: 18px;
    position: relative;
    background-color: #ff9447;
    color: #ffffff;
    border-radius: 10px;
    padding: 10px 20px;
    padding-right: 40px;
  }

  .blog-landing-page-recent h1 {
    font-size: 24px;
    line-height: 31px;
  }

  .blog-landing-feature-section h1 {
    font-size: 24px;
    line-height: 31px;
    font-weight: bold;
  }

  .blog-landing-page-header-text p {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
  }

  .blog-landing-page-header-text h1 {
    margin-bottom: 20px;
  }

  .blog-landing-page-header-input {
    margin-bottom: 40px;
  }

  .blog-landing-feature-section h1 {
    margin-bottom: 40px;
  }

  .blog-landing-page-recent h1 {
    margin-top: 20px;
  }

  .blog-landing-page-row2 {
    margin-top: 20px;
  }

  .blog-landing-page-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    padding-top: 10px;
  }

  .blog-landing-card1 a {
    font-weight: unset;
  }

  .recent-post-btn button {
    font-weight: unset;
  }
}

/* -----------------------------------------BLog opened Page----------------- */
.genre-top-section {
  display: flex;
  width: 50%;
  justify-content: center;
  margin: auto;
  margin-top: 75px;
  margin-bottom: 25px;
}

.genre-top-section a {
  border-radius: 5px;
  width: 120px;
  height: 35px;
  font-weight: normal;
  font-size: 18px;
  padding-top: 5px;
  line-height: 23px;
  color: #ff9447;
  background: #ffe5d9;
  text-align: center;
  text-decoration: none;
}

.genre-top-section h3 {
  font-size: 20px;
  line-height: 31px;
  color: #fff;
  font-family: Poppins;
  font-weight: normal;
  margin-left: 19px;
}

.heading-part-blog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
}

.heading-part-blog h1 {
  font-size: 64px;
  line-height: 83px;
  color: #fff;
  font-weight: bold;
  font-family: Poppins;
  margin-bottom: 20px;
}

.heading-part-blog h3 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.blog-open-quick-brown,
.quick-brown-text {
  font-family: Poppins;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
}

.heading-part-blog-img {
  width: 73vw;
  margin-top: 50px;
  margin-bottom: 50px;
}

.heading-part-blog-img img {
  width: 100%;
}

.heading-part-blog-img2 {
  width: 73vw;
  margin-top: 85px;
  margin-bottom: 85px;
}

.heading-part-blog-img2 img {
  width: 100%;
}

.blog-open-page-gif {
  width: 62vw;
  margin-bottom: 162px;
}

.blog-open-page-gif img {
  width: 100%;
}

.quick-brown-text {
  margin-bottom: 30px;
}

.blog-open-page-content-1 {
  width: 50%;
}

.blog-open-page-content-1 p {
  font-weight: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: justify;
  font-family: Poppins;
}

.blog-open-page-content-1 span {
  line-height: 94px;
  font-size: 72px;
}

.blog-page-speaker-section {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  margin-bottom: 15px;
}

.blog-page-speaker-section h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  color: #fff;
  font-family: Poppins;
}

.blog-page-speaker-section h3 {
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  margin-top: 30px;
}

.blog-page-share-section {
  width: 90%;
  margin-top: 100px;
}

.blog-page-share-section h2 {
  font-weight: bold;
  font-size: 36px;
  font-family: Poppins;
  line-height: 47px;
}

.blog-page-share-icons {
  margin-top: 26px;
}

.blog-page-share-icons img {
  padding-right: 25px;
}

.blog-page-more-like-section {
  width: 90%;
  margin-top: 105px;
}

.blog-page-more-like-section h2 {
  font-weight: bold;
  font-size: 36px;
  font-family: Poppins;
  line-height: 47px;
  margin-bottom: 76px;
}

.blog-open-card1 {
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  /* border-radius: 5px 5px 20px 20px; */
  border-radius: 20px;
  width: 43%;
  display: flex;
  flex-direction: column;
  /* margin-right: 53px; */
}

.blog-open-page-cards-first-row {
  display: flex;
  justify-content: space-around;
}

.blog-open-page-cards-second-row {
  justify-content: space-around;
  display: flex;
}

.blog-open-card1 img {
  width: 100%;
}

.blog-open-card-img {
  position: relative;
}

.blog-open-card-info-namedate {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-top: 20px;
}

.blog-open-card-info-namedate p {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  font-family: Poppins;
  color: #718096;
}

.blog-open-card1 a {
  text-decoration: none;
  color: #fa7616;
  font-weight: bold;
  font-size: 18px;
  font-family: Poppins;
  line-height: 23px;
}

.blog-open-page-cards {
  display: flex;
  width: 95%;
  margin: auto;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
  /* margin-left: 88px; */
}

.blog-open-card-info {
  /* margin-left: 13px;
  margin-top: 22px;
  margin-right: 28px;
  margin-bottom: 13px;
  Check this change for margin
  */
  margin: 22px 28px 13px 13px;
}

.blog-open-card-info h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  /* 800 */

  color: #2d3748;
}

.blog-open-card-btn {
  position: absolute;
  left: 4.79%;
  top: 4.09%;
  border: none;
  background: #ffcbe2;
  border-radius: 5px;
  width: 104px;
  height: 27px;
  color: #ea227a;
  font-family: Poppins;
}

.blof-all-page-comment-section {
  width: 50vw;
  padding-left: 143px;
  margin-top: 600px;
  margin-bottom: 50px;
}

.blof-all-page-comment-section h1 {
  font-weight: bold;
  font-size: 36px;
  font-family: Poppins;
  margin-bottom: 95px;
  line-height: 54px;
}

.name-section-blog-all img {
  width: 41px;
  height: 41px;
  margin-right: 20px;
}

.name-section-blog-all {
  display: flex;
}

.leave-comment-section {
  display: flex;
}

.first-comment-section {
  display: flex;
  margin-top: 80px;
}

.like-emoji-section {
  display: flex;
  margin-left: 60px;
  margin-top: 10px;
}

.like-emoji-section img:nth-child(2) {
  margin-left: 54px;
  margin-right: 54px;
}

.blof-all-page-comment-section h2 {
  font-family: Poppins;
  margin-left: 54px;
  margin-top: 20px;
  font-size: 20px;
  line-height: 30px;
  color: #ea227a;
}

.like-emoji-section p {
  font-family: Poppins;
}

.name-section-blog-all input::placeholder,
.leave-acomment-section input::placeholder {
  font-family: Poppins;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
}

.name-section-blog-all input {
  border: none;
  background: transparent;
  border-bottom: 1px solid #fff;
  margin-bottom: 85px;
  padding-top: 20px;
}

.leave-acomment-section {
  margin-left: 60px;
}

.leave-acomment-section input {
  border: none;
  background: transparent;
  border-bottom: 1px solid #fff;
  width: 30vw;
}

.first-comment-section p {
  font-family: Poppins;
  margin-left: 20px;
  font-size: 20px;
  line-height: 30px;
}

.first-comment-section img {
  width: 41px;
  height: 41px;
}

.blog-open-page-grad1 {
  position: absolute;
  top: 2%;
  z-index: -1;
}

.blog-open-page-grad2 {
  position: absolute;
  top: 22%;
  z-index: -1;
  right: 0;
}

.blog-open-page-grad3 {
  position: absolute;
  top: 82%;
  z-index: -1;
  right: 0;
}

.blog-open-page-grad1 img,
.blog-open-page-grad2 img,
.blog-open-page-grad3 img {
  width: 45vw;
}

.genre-top-section a {
  color: #ea227a;
  background: #ffcbe2;
  border-radius: 5px;
  width: 94px;
  height: 21px;
  padding-top: 0;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
}

.blog-open-page {
  position: relative;
}

@media only screen and (max-width: 1461px) {
  .blog-open-page-cards {
    flex-direction: column;
    align-items: unset;
  }

  .blog-open-card1 {
    width: 40%;
  }

  .blog-open-page-cards-second-row {
    margin-top: 75px;
  }

  .heading-part-blog h1 {
    font-size: 36px;
    line-height: 47px;
  }
}

@media only screen and (max-width: 1000px) {
  .blof-all-page-comment-section {
    padding-left: 30px;
    width: 90vw;
  }

  .carousel-card {
    width: 100% !important;
  }

  .first-comment-section p {
    font-size: 16px;
    line-height: 24px;
  }

  .blof-all-page-comment-section h1 {
    font-size: 36px;
    line-height: 54px;
  }

  .leave-acomment-section input {
    width: 50vw;
  }
}

@media only screen and (max-width: 850px) {
  .blog-open-page-content-1 {
    width: 84%;
  }

  .blog-page-share-section,
  .blog-page-more-like-section {
    width: 85%;
  }

  .genre-top-section a {
    width: 106px;
    height: 31px;
    font-size: 16px;
  }

  .heading-part-blog-img {
    width: 87vw;
  }

  .heading-part-blog-img img {
    height: 340px;
  }

  .heading-part-blog-img2 {
    width: 83vw;
  }

  .blog-img-2-small {
    width: 68vw;
  }

  .blog-page-speaker-section {
    width: 78%;
  }

  .heading-part-blog h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 47px;
  }

  .blog-open-card-info {
    margin-left: 11px;
    margin-top: 19px;
    margin-right: 22px;
    margin-bottom: 11px;
  }

  .blog-open-card-info-namedate {
    margin-bottom: 20px;
  }

  .blog-open-card-info h3 {
    font-size: 18px;
    line-height: 23px;
  }

  .blog-open-card1 a {
    font-size: 16px;
    line-height: 21px;
  }

  .heading-part-blog-img2 {
    margin-top: 94px;
    margin-bottom: 53px;
  }

  .heading-part-blog-img2 img {
    height: 340px;
  }

  .blog-img-2-small {
    margin-top: 34px;
    margin-bottom: 53px;
  }

  .blog-open-page-gif img {
    height: 469px;
  }

  .blog-open-page-content-1 p {
    font-size: 16px;
    line-height: 23px;
  }

  .blog-open-page-gif {
    margin-bottom: 53px;
  }

  .blog-page-share-section {
    margin-top: 50px;
  }

  .blog-page-more-like-section {
    margin-top: 54px;
  }

  .heading-part-blog h1 {
    font-size: 36px;
    line-height: 47px;
  }

  .blog-open-page-gif img {
    height: 469px;
  }
}

@media only screen and (max-width: 675px) {
  .heading-part-blog h1 {
    font-size: 32px;
    line-height: 60px;
  }

  .genre-top-section h3 {
    font-size: 23px;
  }

  .genre-top-section {
    width: 60%;
  }
}

@media only screen and (max-width: 500px) {
  .heading-part-blog-img img {
    height: 186px;
    border-radius: 10px;
  }
  .blog-landing-page-header-input img {
    width: 17px;
    height: 17px;
  }
  .heading-part-blog-img2 img {
    height: 186px;
    border-radius: 10px;
  }

  .blog-open-page-cards-second-row {
    flex-direction: column;
  }

  .blog-open-page-cards-first-row {
    flex-direction: column;
  }

  .blog-open-card1 {
    width: 87%;
    margin-left: 30px;
  }

  .margin-to-card-after-450 {
    margin-top: 50px;
  }

  .heading-part-blog-img2 {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .heading-part-blog-img {
    width: 88vw;
  }

  .heading-part-blog-img2 {
    width: 88vw;
  }

  .blog-img-2-small {
    width: 83vw;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .heading-part-blog-img {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .heading-part-blog h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
  }

  .genre-top-section {
    width: 93%;
    margin-top: 79px;
    margin-bottom: 21px;
  }

  .genre-top-section h3 {
    font-size: 18px;
    line-height: 23px;
  }

  .blog-open-page-content-1 span {
    font-size: 25px;
    line-height: 31px;
  }

  .blog-open-page-gif {
    margin-bottom: 32px;
  }

  .blog-open-page-gif img {
    height: 277px;
  }

  .blog-page-speaker-section {
    margin-top: 53px;
    margin-bottom: 18px;
  }

  .blog-page-speaker-section h3 {
    margin-top: 18px;
  }

  .blog-page-share-section h2,
  .blog-page-more-like-section h2 {
    font-size: 24px;
    line-height: 31px;
  }

  .blog-page-share-icons {
    margin-top: 23px;
  }

  .blog-page-more-like-section {
    margin-top: 38px;
  }

  .blog-page-more-like-section h2 {
    margin-bottom: 25px;
  }

  .blog-open-page-content-1 p {
    font-size: 16px;
    line-height: 21px;
  }

  .blog-img-2-small img {
    border-radius: 20px;
    height: 220px;
  }

  .genre-top-section a {
    line-height: 18px;
    width: 86px;
    height: 25px;
    font-size: 14px;
  }

  .heading-jane-doe {
    font-size: 18px;
    line-height: 23px;
    color: #2d3748;
    font-weight: 500;
  }

  .blog-open-card-btn {
    width: 81px;
    height: 18px;
    font-size: 11px;
  }

  .blog-page-speaker-section h3 {
    font-size: 18px;
    line-height: 23px;
  }

  .to-make-br-none {
    display: none;
  }

  .blog-open-quick-brown {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
  }
}

@media only screen and (max-width: 400px) {
  .blog-open-card1 {
    margin-left: 26px;
    width: 84%;
  }
}

/* ------------------BLog all --------------------------------- */
.popukar-now-section-search {
  background: #303030;
  /* padding-top: 348px; */
  padding-bottom: 40px;
  text-align: center;
}

.popukar-now-section-search input {
  width: 47vw;
  height: 64px;
  padding-left: 32px;
  background: #000000;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  border: none;
  font-size: 22px;
  line-height: 30px;
}
@media (max-width: 768px) {
  .popukar-now-section-search input {
    width: 80vw;
  }
}
.popukar-now-section-search-input {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

.popukar-now-section-search-input img {
  transform: translateX(-40px);
  position: relative;
  z-index: 2;
}

.popukar-now-section-search-input input::placeholder {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #fff;
}

.blog-all-navbar {
  background-color: #090b20;
  position: relative;
}
.blog-all-navbar .first.ImageCircles {
  z-index: 0;
}
.blog-all-input input {
  background: #303030;
  position: relative;
  z-index: 1;
}

.blog-all-page-margintop {
  margin-top: 50px;
}

.blog-landing-card1 {
  background: #303030;
}

.blog-all-post-page {
  position: relative;
}

.blog-all-post-change-back {
  background: unset;
  position: relative;
}

.blog-all-navbar {
  padding-top: 50px;
}

.blog-all-page-search-text {
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  margin-bottom: 53px;
}

.blog-all-post-change-back span {
  font-size: 18px;
  line-height: 27px;
  font-family: Poppins;
}

.blog-landing-card1 a {
  color: #ea227a;
  font-family: Poppins;
}

.blog-open-card-info h3,
.blog-open-card-info-namedate p {
  color: #fff;
}

.blog-all-post-margin-top {
  margin-top: 50px;
}

/* FOOTER */

.BottomCities {
  padding: 15% 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
}

.BottomCities img.BottomCities svg {
  width: 100vw;
}

.BottomCities img.Front {
  position: relative;
  transform: translate(0px, 4px);
  z-index: 2;
}

.BottomCities svg.Mid {
  position: absolute;
  z-index: 1;
  left: 0;
  transform: translate(0px, -35%);
}

.BottomCities img.Back {
  position: absolute;
  z-index: 0;
  transform: translate(0px, -50%);
  left: 0;
}

@media (min-width: 144px) {
  footer .data img {
    width: 22px;
    height: 22px;
  }
  footer .data {
    background-color: #2d2d2d;
    padding: 5px 24px 0px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    line-height: 22px;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    /* padding-bottom: 100px;
    padding-top: 40px;
    padding-bottom: 30px;
    padding-top: 10px;
    padding-left: 36px;
    padding-right: 54px; */
  }
  .margin-botm-span {
    margin-bottom: 30px !important;
    font-weight: 300;
  }
  .footer.Left span {
    padding-left: 18px;
  }
  .footer.Left a {
    padding-left: 18px;
  }
  footer span {
    color: white;
  }
  footer .data p {
    font-weight: 300;
    line-height: 16px;
    margin-bottom: 27px;
  }
  footer .Left,
  footer .Right {
    width: 50%;
  }
  .footer.Left {
    border-right: 1px solid #ffffff;
    padding-right: 30px;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
  }
  footer .Right {
    padding: 0 25px;
  }
  Footer .Right li {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  footer a {
    font-size: 18px;
    color: white;
    text-decoration: none;
    line-height: 22px;
  }
  footer p.bottomText {
    margin: 0;
    position: absolute;
    opacity: 0.7;
    bottom: 60px;
    right: 60px;
  }
}

@media (min-width: 768px) {
  footer .data {
    font-size: 16px;
  }
  footer .data img {
    width: 45px;
    height: 45px;
  }
  footer span {
    margin-bottom: 18px;
  }
  footer .Left {
    width: 39%;
  }
  footer .Right {
    width: 85%;
    padding: 5% 19%;
    padding-right: 28%;
  }
  .footer.Right ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  Footer .Right li {
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  Footer .Right li {
    font-size: 24px;
  }
  footer .data img {
    width: 99px;
    height: 99px;
    margin-bottom: 10px;
  }
  footer .data {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  .contactForm {
    max-width: 60vw;
    height: max-content;
    position: relative;
  }
  .contactContainer {
    display: flex;
    width: 97%;
    justify-content: space-between;
  }

  .contact h1 {
    font-size: 48px;
    margin-left: 60px;
  }
  .contactForm .inputBox span {
    font-size: 18px;
  }
  .contactForm .inputBox label,
  .contact p {
    font-size: 16px;
  }
  button.showContactForm {
    color: white;
    position: relative;
    font-size: 18px;
    align-items: center;
    display: flex;
  }
  .contact .contactLeft {
    position: relative;
    margin: -40px 0 0 54px;
  }
  .contact p.newFeatures {
    font-size: 18px;
    margin: 0 15px;
  }
  .contact h1 > img {
    position: relative;
    transform: translateX(-10%);
    margin-right: 45px;
  }
  .contact img.line1 {
    transform: translate(50%, -80%);
    padding-left: 52px;
  }
  .contact img.Line2 {
    margin: 0 10px 0 20px;
    transform: scaleY(5.5) translateY(-0.5%);
  }
  .contact img.circle2 {
    width: 14px;
    height: 14px;
    margin: 10px -7px 0 auto;
  }
  img.roundLine1 {
    margin: 0 0 10px;
  }
  img.Line3Right {
    margin: 0 0 0 auto;
    transform: scaleY(3) translateY(30%);
  }
  .contact img.Line4 {
    margin: 0 10px 10px 20px;
  }
  .contact img.console {
    margin-right: 10px;
  }
}

.blog-landing-feature-section1-info a {
  color: #fff;
}
.blog-landing-feature-section1-info button {
  background-color: transparent;
  border: 1px solid #0094ff;
}
.recent-post-btn button {
  background-image: none;
  background-color: transparent;
  border: 1px solid #0094ff;
}
.blog-landing-card1 a {
  color: #0094ff;
}
.blog-open-card-btn {
  color: #0094ff;
}
.blog-landing-semi-nav ul li a:hover {
  color: #0094ff;
}
.genre-top-section a {
  color: #0094ff;
}
.genre-top-section {
  margin-top: 90px;
}
.blof-all-page-comment-section h2 {
  color: #0094ff;
}
.blog-all-post-change-back span {
  color: #fff;
}

.home-page-background {
  min-height: 113vh;
  /* padding: 51rem 0; */
  overflow: hidden;
}
.home-page-background::after {
  content: "";
  position: absolute;
  height: 180px;
  left: -10%;
  right: -10%;
  z-index: 2;
  border-radius: 118%;
  bottom: -49px;
  background: #000;
}
.home-sun img {
  position: absolute;
  width: 50%;
  /* bottom: -115px; */
  z-index: 0;
  left: 19%;
}
.home-page-globe {
  width: 136%;
  height: 93vh;
  position: absolute;
  left: -48%;
  top: 22%;
  z-index: 1;
}
.home-page-globe canvas {
  width: 124% !important;
  height: 128vh !important;
  object-fit: contain;
  /* transform: rotate(42.64deg); */
}
@media (max-width: 1500px) {
  .home-page-globe canvas {
    width: 112% !important;
  }
}
.home-page-background h1 {
  position: absolute;
  /* top: 75px; */
  /* left: 14%; */
  width: 100%;
}

.scroll-down-text {
  position: absolute;
  /* bottom: 10%; */
  top: 64%;

  width: 100%;
  /* left: 25%; */
}
.scroll-down-text svg {
  margin-top: 30px;
}
.scroll-down-text h1 {
  position: unset;
  z-index: 1;
}

.swiper-initialized {
  padding-top: 5% !important;
  padding-bottom: 5% !important;
}

/* ---------privacy-policy---------------- */
.privacy-page-header {
  /* background-color: #1B1B1B; */
  padding: 12% 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./assets/images/Privacy/Group 237.svg");
}
.privacy-page-header h1 {
  text-align: center;
  font-weight: bold;
  font-size: 84px;
  line-height: 112px;
  /* identical to box height */

  letter-spacing: 0.03em;
}
.privacy-policy-page-content {
  width: 99%;
  margin: 0 auto;
  margin-top: 105px;
  display: flex;
  justify-content: space-around;
}
.your-account-headings h1,
.your-account-headings p {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.03em;
}
.your-account-headings p {
  margin: 56px 0;
}
.your-account-content {
  width: 50%;
}
.your-account-headings {
  width: 30%;
}
.your-account-headings h1 {
  color: #0094ff;
}
.your-account-content h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.03em;
}
.your-account-content h2 {
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  float: right;
  margin-top: 26px;
}
.your-account-content p {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.03em;
  margin-top: 26px;
  margin-bottom: 42px;
}
table,
td,
th {
  border: 0.5px solid rgba(255, 255, 255, 0.6);
}
table {
  border-bottom-left-radius: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
}
.your-account-content-table {
  margin-bottom: 80px;
  margin-top: 30px;
}
td {
  padding: 39px 0;
  text-align: center;
}
.your-account-accordian {
  border: 1px solid #898989;
  margin-bottom: 40px;
  margin-top: 20px;
}
.accordian-titile div {
  padding-left: 20px;
}
.accordion-content {
  font-size: 18px;
  padding-left: 35px;
  background: #211e1e;
  padding-right: 41px;
  border-top: 1px solid #898989;
  border-bottom: 1px solid #898989;
  padding-top: 42px;
  padding-bottom: 24px;
  line-height: 24px;
}
.accordian-titile {
  font-size: 24px;
  cursor: pointer;
  display: flex;
  padding-top: 17px;
  padding-bottom: 30px;
  padding-left: 35px;
  padding-right: 35px;
  border-bottom: 1px solid #898989;
  line-height: 32px;
}
@media (max-width: 1200px) {
  .home-page-background {
    height: unset;
    padding: 51rem 0;
    min-height: unset;
    /* overflow: hidden; */
  }
  .your-account-content p {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.03em;
    margin-top: 19px;
    margin-bottom: 32px;
  }
  .your-account-content h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
    letter-spacing: 0.03em;
  }
  .your-account-headings h1,
  .your-account-headings p {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.03em;
  }
  .privacy-page-header h1 {
    font-size: 62px;
    line-height: 80px;
  }
  .your-account-headings p {
    margin: 26px 0;
  }
  .your-account-headings h1 {
    margin-bottom: 32px;
  }
  .your-account-content {
    width: 55%;
  }
}
@media (max-width: 834px) {
  .privacy-policy-page-content {
    flex-direction: column;
  }
  .your-account-headings,
  .your-account-content {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .privacy-page-header h1 {
    font-size: 40px;
    line-height: 48px;
  }
  .privacy-policy-page-content {
    margin-top: 60px;
  }
  .your-account-headings {
    margin-bottom: 60px;
  }
}

.blog-open-recent-cards {
  display: flex;
  width: 93%;
  margin: 0 auto;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.blog-open-card1 {
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
.blog-open-card1 {
  border-radius: 20px;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 5%);
  width: 40%;
  background: #303030;
}
.blog-open-card1 img {
  border-radius: 20px 20px 0 0;
  height: 250px;
  object-fit: cover;
}
.feautred-articles-blogopen-cardimg-after-500 {
  display: none;
}
.Featured-articles-blogopen h1 {
  font-weight: bold;
  font-size: 48px;
  margin-left: 100px;
  line-height: 62px;
  margin-bottom: 70px;
  font-family: Poppins;
  position: relative;
}
.feautred-articles-blogopen-cards-landing {
  width: 92%;
  margin: 0 auto;
  position: relative;
}
.feautred-articles-blogopen-card {
  display: flex;
  justify-content: space-around;
  padding-bottom: 230px;
}
.feautred-articles-blogopen-cardimg {
  width: 45%;
}
.feautred-articles-blogopen-cardimg img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: 20px;
}
.feautred-articles-blogopen-card-info {
  width: 38%;
}
.blog-landing-feature-section1.Alternate {
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
/* @media (max-width: 1100px) {
  .carousel-card {
    width: 86%; 
    margin: 0 35px;
    padding: 20px;
    padding-left: 10px;
  }
  .carousel .slide img {
    width: 68vw !important;
  }
  .carousel-text h1 {
    margin-top: 15px;
  }
} */
/* @media (max-width: 830px) {
  .carousel-text p {
    font-size: 14px;
  }
  .carousel-text h1 {
    font-size: 18px;
  } 
} */

/* .why-too-decimal-image{position: fixed;top: 0;overflow: hidden;left: 0;z-index: -1;} */
.why-too-decimal-image img {
  position: unset;
  height: 100%;
}
nav {
  z-index: 3;
  background-color: #100f2e;
}
.home-page-background {
  background-color: #100f2e;
}
.home-page-section-intro-img img {
  transition: all 0.6s ease-in-out;
}
.home-page-section-intro-img img:hover {
  transform: translate3d(10px, 10px, -20px);
}
@media (max-width: 1200px) {
  .blog-open-card1 {
    width: 44%;
  }
}
@media (max-width: 600px) {
  .blog-open-card1 {
    width: 46%;
    margin-left: 0;
  }
  .blog-open-card1 img {
    height: 250px !important;
  }
  .blog-open-recent-cards {
    width: 96%;
  }
}
@media (max-width: 500px) {
  .blog-open-card1 {
    margin: 20px auto;
    width: 90%;
  }
}
.view-all-text-blog-landing {
  text-align: center;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: 29px;
  border: 1px solid #0094ff;
  border-radius: 10px;
  width: 140px;
  padding: 14px 0;
}
.blog-open-card1 a {
  color: #0094ff;
  cursor: pointer;
}
.heading-part-blog-img img {
  border-radius: 20px;
  height: 580px;
  object-fit: cover;
}
.heading-part-blog-img {
  margin-left: auto;
  margin-right: auto;
}
.Blog-open-page-text {
  width: 80vw;
  margin-bottom: 50px;
  margin: 0 auto;
}
.blog-open-page-text h2 {
  margin-left: 114px;
  font-family: Poppins;
  margin-top: 54px;
  margin-bottom: 82px;
  font-size: 48px;
  line-height: 62px;
}
.Blog-open-page-text img {
  border-radius: 20px;
}
blockquote p {
  font-style: italic;
  font-weight: 500;
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  color: #fff;
}
.Blog-open-page-text p p {
  margin-top: 20px;
}
.Blog-open-page-text p {
  font-weight: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: justify;
  font-family: Poppins;
}
.Blog-open-page-text p a {
  color: #fff;
  font-style: normal;
}
.blog-landing-feature-section1-info {
  margin-left: 0;
}

.Blog-open-whole-section blockquote p {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.Blog-open-whole-section blockquote p::after,
.Blog-open-whole-section blockquote p::before {
  content: url("assets/images/blogopen/QuotesOpen (1).svg");
  display: block;
  margin: 0 10px;
}
.Blog-open-whole-section blockquote p::after {
  transform: rotateZ(180deg);
}
.Blog-open-whole-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #090b20;
  /* align-items: center; */
  margin-top: 70px;
}

.blogOpen-first-heading {
  margin-top: 50px;
}
.blogOpen-first-heading h1 {
  padding-top: 10px;
  font-size: 4em;
}
.blog-open-semi h2 {
  font-size: 2.5em;
  margin: 1em auto;
}
.Blog-open-whole-section {
  text-align: center;
}
figcaption {
  text-align: center;
}
.Blog-open-whole-section p {
  /* text-align: left; */
  /* width: max-content; */
  margin: auto;
}
.blog-open-semi p {
  text-align: center;
}
.Blog-open-whole-section p img {
  width: 70vw;
  margin: 20px auto;
}
.Blog-open-whole-section figure img {
  display: block;
  width: 45vw;
  object-fit: contain;
  margin: 50px auto;
}
.Blog-open-whole-section .image-style-side {
  margin: 20px 0px 20px auto;
  float: right;
}

.Typewriter {
  display: inline;
}
.Typewriter__wrapper {
  color: white;
}

/* Animations */
/* 
.blinkingText {
	animation: blink 3s ease 1;
}

@keyframes blink {
	0% {
		opacity: 0.2;
		color: #fff;
	}
	49% {
		opacity: 0.5;
		color: #fff;
	}
	60% {
		opacity: 0.4;
	}
	99% {
		opacity: 0.2;
	}
	100% {
		opacity: 0;
		display: none;
		color: #fff;
	}
} */
body {
  overflow-x: hidden;
}
.why-decimal-anim-svg svg:nth-child(2) {
  position: absolute;
  left: 0;
}

.why-decimal-anim-svg svg {
  left: 4%;
  position: absolute;
  height: 100%;
  top: -1%;
}
/* .we-are-just-different-section{display: none;transition: all 0.7s ease-in-out;} */
/* .we-are-just-different-section{display: none;transition: all 0.7s ease-in-out;} */
/* .galaxy-wrapper{overflow-y: hidden;} */
.why-decimal-heading {
  margin-bottom: 62px;
}
.flex-whydecimal-wrap {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}
.why-choose-too-decimal-container {
  margin-left: unset;
  z-index: -1;
}
.scg-anim {
  height: 200vh;
}

.home-page-svg-animation {
  /* height: calc(100vh - 100px); */
  height: calc(100vh - 100px);
  background: url("./assets/Home/Vector(8).svg") no-repeat;
  /* background-attachment: scroll; */
  background-position: 50%;
  /* background-image: url(/static/media/Ad\ flow-01.2a5d3b2f.svg); */
  background-attachment: fixed;
  /* background-size: 70vw; */
}
.Blog-open-whole-section {
  text-align: unset;
}

/* .slick-initialized .slick-slide{margin: 0 100px;} */
.blog-open-card-info h3 {
  text-align: left;
}
.blog-open-card1 a {
  text-align: left;
  font-family: Poppins;
}
.Blog-open-whole-section .blog-open-card-info a {
  text-align: left !important;
}
.blog-open-card-info-namedate p {
  text-align: unset;
  width: unset;
  margin: unset;
}

.home-page-satelite img {
  position: absolute;
  left: 90vw;
  bottom: 41%;
  z-index: 2;
  transform: matrix(-1, 0, 0, 1, 0, 0) scaleX(-1);
}
.blog-all-post-page,
.author-name-text-blog,
.blog-open-card1 a,
.blog-landing-semi-nav ul li a,
.linkToAllBlogs p {
  font-family: Poppins !important;
}
.blog-open-card1 a {
  font-family: Poppins;
}
iframe {
  z-index: -2 !important;
}

.blog-landing-feature-section1-info a {
  font-family: Poppins;
  font-size: 18px;
  background: transparent;
  border: 2px solid #0094ff;
  border-radius: 10px;
  padding: 10px 30px;
  line-height: 27px;
}
.read-more-blog-open {
  margin-top: 28px;
}
.blog-landing-feature-section1-info p {
  margin-top: 7px;
}
.author-name-text-blog {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.article-read-time {
  font-size: 17px !important;
  margin: 0 !important;
}
.blog-landing-feature-section {
  margin-left: unset;
  margin-right: unset;
}
.blog-landing-feature-section h1 {
  margin-left: 100px;
}
.blog-landing-feature-section1-info button {
  font-size: 14px;
  line-height: 21px;
  border: none;
  height: 27px;
  color: #0094ff;
  background: #ffcbe2;
  width: 94px;
  border-radius: 5px;
}

.blog-open-card1 {
  margin-bottom: 50px;
}
.blog-open-card1 {
  width: 22%;
}
.blogOpen-first-heading,
.blog-open-semi {
  text-align: center;
}
.blog-landing-page-header-input input,
.blog-all-input input {
  color: #fff;
  z-index: 1;
}
@media (max-width: 1500px) {
  .blog-open-card1 {
    width: 29%;
  }
}
@media (max-width: 1000px) {
  /* .blog-landing-page-header-text h1{font-size: 36px;
					line-height: 54px;}
					.blog-landing-page-header-text p{font-size: 18px;
						line-height: 27px;} */
  .blog-landing-page-header-text h1 {
    margin-bottom: 23px;
    text-align: center;
  }
  .author-name-text-blog {
    flex-direction: column;
    margin: 10px 0;
  }
  .blog-all-post-page .second {
    position: absolute;
    left: 5%;
    top: 10%;
    background-color: rgba(0, 11, 114, 0.4);
    filter: blur(490px);
    z-index: 0;
    right: 0;
    bottom: 0;
  }
  .blog-landing-page-header-text p {
    margin-bottom: 28px;
  }
  .blog-landing-page-header-input {
    width: 75%;
  }
  .blog-landing-page-header-text {
    padding: 0 10%;
  }
  .blog-open-card1 {
    margin-bottom: 40px;
  }
  .blog-open-card1 {
    width: 45%;
  }
  .blog-open-recent-cards {
    width: 95%;
  }
  .blog-open-card1 {
    margin-left: auto;
    margin-right: auto;
  }
  .blog-landing-page-header {
    background: none;
  }
  .blog-landing-page-header {
    padding-top: 40px;
  }
  .Featured-articles-blogopen h1,
  .blog-landing-feature-section h1 {
    font-size: 36px;
    line-height: 54px;
    margin-left: 0;
    text-align: center;
  }
  .blog-landing-page-recent {
    margin-bottom: 30px;
  }
  .feautred-articles-blogopen-cards-landing {
    width: 80%;
  }
  .feautred-articles-blogopen-cardimg img {
    height: 350px;
    object-fit: cover;
  }
  .blog-landing-page-header-input {
    margin-bottom: 30px;
  }
  .blog-landing-feature-section1-info button {
    display: none;
  }
}
@media (max-width: 550px) {
  .blogOpen-first-heading h1 {
    font-size: 35px;
  }
  .feautred-articles-blogopen-cardimg img {
    height: 200px;
  }
  .blog-open-card1 {
    width: 91%;
  }
  .Blog-open-whole-section figure img {
    display: block;
    width: 90vw;
    object-fit: contain;
    margin: 25px auto;
    border-radius: 10px;
  }
}
@media only screen and (max-width: 890px) {
  .feautred-articles-blogopen-card-info {
    width: 100%;
  }
  .feautred-articles-blogopen-cardimg {
    width: 100%;
  }
}

.filter-checkbox-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
}
.filter-checkbox-group div.selected.active {
  background-color: #00c2ff;
  width: 12px;
  border: 2px solid white;
}
.filter-checkbox-group div.selected {
  width: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: rgb(224, 224, 224);
}
.filter-checkbox-group label {
  color: #fff;
}
.galaxy-bacgkground img {
  z-index: -1;
}
.galaxy-bacgkground {
  z-index: -1;
}
.why-too-decimal-section,
.blog-open-recent-article.blog-open-recent-article-bloglanding {
  z-index: 1;
}
.first-join-wait-list {
  margin-bottom: 250px;
}
@media (max-width: 1600px) {
}

.react-typewriter-text {
  font-size: 18px !important;
  padding-bottom: 13px !important;
  font-weight: 400 !important;
  display: inline !important;
  padding: 10px 0 !important;
  line-height: 21px !important;
}
.react-typewriter-text-wrap {
  margin: 9px 0;
}

ul.control-dots {
  display: none;
}

h1.differentGIFText {
  background-image: url("./assets/Home/matrixGIF.gif");
  background-clip: text;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  color: transparent;
  margin-left: 5px;
}

@media (min-width: 1200px) {
  .home-sun img {
    /* right: 18%;top: -180px; */
    /* top: -179px; */
  }
  .Blog-svg {
    position: absolute;
    width: 636px;
    height: 636px;
    filter: blur(200px);
    -webkit-filter: blur(200px);
    bottom: 0;
    z-index: 0;
  }
  .first.Blog-svg {
    background: rgba(0, 11, 114, 0.6);
    left: -25% !important;
    top: -18% !important;
  }
  .second.Blog-svg {
    background: rgba(0, 11, 114, 0.4);
    right: -25% !important;
    bottom: 24% !important;
  }
  .ImageCircles {
    position: absolute;
    width: 636px;
    height: 636px;
    filter: blur(200px);
    -webkit-filter: blur(200px);
    bottom: 0;
    z-index: 0;
  }
  .aboutUsHead .left,
  .aboutUsHead .right {
    position: relative;
    z-index: 1;
  }
  .first.ImageCircles {
    background: rgba(0, 11, 114, 0.6);
    left: -25% !important;
    bottom: -50% !important;
  }
  .second.ImageCircles {
    background: rgba(0, 11, 114, 0.4);
    right: -25% !important;
    bottom: -50% !important;
  }
  .aboutsUsAwards h1 {
    display: block;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .aboutsUsAwards,
  .aboutUsPartners,
  .aboutUsHead {
    position: relative;
    margin-bottom: 0;
    padding: 30px 4.5vw 60px;
  }
}

.join-email-input .input-feedback {
  position: absolute;
  bottom: 51px;
  color: red;
}
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(20px);
  }
}
#scroll-wheel {
  animation: scroll ease 3s infinite;
}

.carousel-new {
  height: 400px;
  overflow: hidden;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  margin-top: 70px;
}
.carousel-track {
  display: flex;
  width: calc(730px * 10);
  animation: autoscroll 28s linear infinite;
}
.carousel-card:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.carousel-card {
  transition: all 0.7s ease-in-out;
  /* height: 314px; */
  min-width: 730px;
  max-width: unset !important;
  display: flex;
  align-items: center;
  border-radius: 13px;
}
@keyframes autoscroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-697px * 10));
  }
}
.carousel-text {
  width: 490px;
}

.carousel-card3 .carousel-text .carousel-text p {
  color: #000 !important;
}
.carousel-card3 .carousel-text .carousel-text h1 {
  color: #000;
}
.AboutUs span {
  flex-direction: row;
  align-items: center;
}
.AboutUs span p {
  color: #05ff00;
}
@media (min-width: 1360px) {
  .carousel-text p {
    font-size: 28px !important;
    margin-bottom: 10px;
  }
}
@media (max-width: 1300px) {
  .home-page-satelite img {
    right: 3%;
    bottom: 290px;
    width: 270px;
  }
}
@media (max-width: 1200px) {
  .react-typewriter-text {
    font-size: 16px !important;
    line-height: 19px !important;
  }
  .first-join-wait-list {
    margin-bottom: 150px;
  }
  /* .home-page-satelite img{right: -34%;top: 190px;} */
  .why-decimal-anim-svg {
    position: absolute;
  }
  .why-choose-too-decimal-container {
    margin: 0;
  }

  .join-email-input .input-feedback {
    position: absolute;
    bottom: 35px;
    left: 11%;
    color: red;
  }
}
@media (max-width: 500px) {
  .join-email-input .input-feedback {
    position: absolute;
    bottom: 20px;
    color: red;
    left: 15%;
  }
  .Blog-open-whole-section blockquote p::after,
  .Blog-open-whole-section blockquote p::before {
    width: 60%;
  }
}
@media (max-width: 400px) {
  .join-email-input .input-feedback {
    position: absolute;
    bottom: 15px;
    color: red;
    left: 11%;
  }
}

@media (max-width: 1100px) {
  .carousel-new {
    margin-top: 20px;
  }
  .carousel-card {
    height: 270px;
    margin: 0 25px;
  }
  .carousel-track {
    width: calc(470px * 10);
    animation: autoscroll 25s linear infinite;
  }
  .carousel-card {
    width: 470px;
    display: flex;
    align-items: center;
  }
  @keyframes autoscroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-470px * 10));
    }
  }
  .carousel-text {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .home-page-satelite img {
    right: -26%;
    bottom: 49%;
    width: 130px;
  }
  /* .home-page-globe{left: -55%;
					top: -23%;}
					.home-page-globe canvas{    width:1% !important;
						height: 156vh !important;} */
  .home-page-background {
    /* height: 76vh; */
  }

  .scroll-down-text svg {
    width: 22px;
  }
}
.scroll-down-text svg {
  z-index: 2;
  cursor: pointer;
}
@media (max-width: 760px) {
  .carousel-new {
    height: 280px;
  }
  .carousel-text p {
    font-weight: 300;
    /* font-size: 12px; */
    /* line-height: 14px; */
  }
  .carousel-text h1 {
    font-size: 18px;
    line-height: 22px;
  }
  .carousel-text {
    width: 233px;
  }
  .bike-img-bg img {
    top: -4px;
  }
  .carosel-card-img {
    width: 75%;
  }
  .carousel-card {
    padding: 17px;
    /* height: 150px; */
    margin: 0 12px;
    padding-left: 8px;
  }
  .carosel-card-img img {
    width: 80%;
    margin-left: 10px;
  }
  .carousel-text h1 {
    margin-top: 14px;
  }
  .carousel-track {
    width: calc(270px * 3);
    animation: autoscroll 15s linear infinite;
  }
  .carousel-card {
    /* width: 190px !important; */
    display: flex;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .carousel-card {
    width: 110% !important;
  }
}
@media (max-width: 450px) {
  .carousel-card {
    width: 110% !important;
  }
  .carousel-text p {
    /* font-size: 8px !important; */
  }
  .carousel-text h1 {
    /* font-size: 12px !important; */
    /* margin-top: 8px !important; */
  }
}
@media (max-width: 660px) {
  .carousel-text p {
    font-weight: 300;
    /* font-size: 10px; */
    /* line-height: 14px; */
  }
  .home-page-globe {
    /* width: 100%;
    height: unset;
    position: absolute;
    left: unset; */
    top: 150px;
    /* z-index: 1; */
  }
}
@media (max-width: 600px) {
  .react-typewriter-text {
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 14px !important;
  }
  .industry-section-text h1 {
    font-size: 36px;
    line-height: 43px;
  }
  .industries-we-serve-section p {
    font-size: 24px;
    line-height: 30px;
  }
  .carousel-new {
    height: 280px;
  }
  .carousel-text p {
    font-weight: 300;
    font-size: 10px;
    /* line-height: 14px; */
  }
  .carousel-text h1 {
    font-size: 14px;
    line-height: 22px;
    margin-top: 9px;
  }
  .carousel-text {
    width: 233px;
  }
  .bike-img-bg img {
    top: -4px;
  }
  .carosel-card-img {
    width: 100%;
  }
  .carousel-card {
    padding: 17px;
    height: 150px;
    margin: 0 12px;
    padding-left: 8px;
  }
  .carosel-card-img img {
    width: 85%;
  }
  .carousel-track {
    width: calc(270px * 3);
    animation: autoscroll 15s linear infinite;
  }
  .carousel-card {
    /* width: 190px !important; */
    display: flex;
    align-items: center;
  }
  @keyframes autoscroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-270px * 3));
    }
  }
}
.why-decimal-anim-svg svg {
  z-index: -1;
}
.why-decimal-anim-svg img {
  position: absolute;
  left: -9%;
  z-index: -1;
}

footer {
  position: relative;
}
svg #whole-wheel {
  animation: App-logo-spin infinite 20s linear;
  transform-origin: 71.8% 39%;
}
.BottomCities svg {
  width: 100vw;
  max-width: 100%;
  height: auto;
}
.BottomCities img.Back {
  position: absolute;
  /* height: 500px; */
  width: 98%;
  transform: translate(2%, -48%);
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.aboutUsPartners {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.sort-by-posted {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1em 10em 1em 6em;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-bottom: 4rem;
}
.pagination img {
  cursor: pointer;
}
.pagination-list {
  display: flex;
  gap: 1.2rem;
  font-size: 1.8rem;
  align-items: center;
}
.pagination-list li {
  cursor: pointer;
  color: #ffffff;
}
li.selected-page {
  background: #f8f8f8;
  border-radius: 50%;
  color: #000;
  padding: 0rem 1rem;
}
.wrapper2 {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.wrapper2 select {
  background: #000;
  color: #ffffff;
  border-radius: 5px;
  font-size: 16px;
  padding: 5px 10px;
}
.wrapper2 p {
  color: #ffffff;
}
.m-tabs {
  display: block;
}
.m-tabs select {
  background-color: #1b1b1b;
  color: white;
}
.wrapper2 button {
  color: white;
}
/* @media screen and (max-width: 1066px) and (min-width: 831px) {
  .carousel-text p {
    font-size: 16px;
  }
}
@media screen and (max-width: 906px) and (min-width: 831px) {
  .carousel-text p {
    font-size: 14px;
  }
} */

@media screen and (max-width: 768px) {
  .sort-by-posted {
    flex-wrap: wrap;
    padding: 1.8em 1.5em;
    justify-content: center;
    align-items: center;
  }
}

.contact h1 {
  justify-content: flex-start;
}
.second-animation-svg {
  left: -1%;
}
.second-animation-svg {
  left: 4% !important;
  top: -3% !important;
  width: 15%;
}

.blinking-cursor {
  padding-bottom: 18px !important;
  font-weight: 700 !important;
  font-size: 50px !important;
  color: #05ff00 !important;
  animation: 0.75s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #05ff00;
  }
}

.blog-all-post-page {
  background-color: #090b20;
}
.Featured-articles-blogopen-blog-all,
.blog-landing-feature-section {
  position: relative;
  z-index: 1;
}

@media (min-width: 144px) {
  .contactContainer {
    display: flex;
  }
  .backgroundAssets {
    width: 95vw;
    margin: 0px auto;
  }
  img.image1BG {
    display: none;
  }
  .image2BG {
    width: 70vw;
    float: right;
  }
  footer .imgDiv {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 10px 5px;
  }
  footer .imgDiv img {
    width: 22px;
    height: 22px;
    margin: 0;
  }
}
@media (min-width: 380px) {
  .backgroundAssets {
    width: 90vw;
    margin: 0px auto;
  }
}
@media (min-width: 768px) {
  .image2BG {
    float: right;
    width: 20vw;
  }
  footer .imgDiv {
    gap: 1rem;
    padding: 10px 18px;
  }
  footer .imgDiv img {
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1200px) {
  .backgroundAssets {
    width: 100%;
    max-width: 60vw;
  }
  img.image1BG {
    position: absolute;
    top: 0;
    right: 10vw;
    z-index: 0;
    width: 20vw;
    display: block;
  }
  img.image2BG {
    width: 20vw;
  }
}

/* .contact{
	background: #090b20;
} */
@media (min-width: 1200px) {
  .contactForm .react-typewriter-text {
    font-size: 20px !important;
    margin: 20px 0;
  }
}
.contact .blinking-cursor {
  font-size: 24px;
  font-weight: 500;
  padding: 0;
  color: white;
}
.contactForm .blinking-cursor {
  font-size: 24px !important;
  padding: 0 !important;
}
/* .forhome-oneandtwo::-webkit-scrollbar{width: 0 !important} */
/* .forhome-oneandtwo{
	max-height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}
.home-page-background,.home-page-section-intro{scroll-snap-align: start;
	height: 100vh;} */
.contactForm .inputBox label {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 0.5em;
}
.contact label .react-typewriter-text,
#displayText .react-typewriter-text {
  margin: 0;
}

#displayText {
  display: none;
  color: #05ff00;
  font-size: 18px;
  gap: 0.8em;
}

.home-page-section-intro {
  /* height: unset; */
}
.blog-all-post-page .ImageCircles {
  width: 60%;
  height: unset;
}

@media (min-width: 1200px) {
  .blog-landing-feature-section1-info h3 {
    font-size: 20px;
  }
}
.blog-all-post-page {
  position: relative;
  overflow: hidden;
}
.blog-all-post-page .second {
  width: 636px;
  position: absolute;
  height: 636px;
  right: 10%;
  bottom: 15%;
}

#SortingOptions {
  flex-direction: column;
  gap: 0.2em;
  width: 150px;
  position: absolute;
  right: -160px;
  top: 10px;
  border: 1px solid rgb(238, 255, 242);
  border-bottom: 0.5px solid rgb(238, 255, 242);
  border-radius: 5px;
  transition: all 0.7s ease-in-out;
  z-index: 2;
}
#SortingOptions input {
  background: none;
  outline: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0.7em 0.8em;
  background-color: #6e6e6e;
  z-index: 2;
}
#SortingOptions input:nth-child(1) {
  border-radius: 5px 5px 0px 0;
}
#SortingOptions input:nth-child(2) {
  border-radius: 0px 0px 5px 5px;
}

#SortingOptions input:hover {
  color: white;
  transition: all 0.1s ease-in-out;
  background-color: #303030;
}
.sort-by-posted .filter-checkbox-group {
  margin: auto auto auto 30px;
  position: relative;
}

/* .home-page-section-intro{height: unset;} */
/* .blog-all-post-page .ImageCircles{width: 60%;height: unset;}  */
@media (max-width: 1330px) {
  footer .Right {
    padding-right: 23%;
  }
  footer a {
    font-size: 12px;
    line-height: 22px;
  }
}
@media (max-width: 1200px) {
  /* footer .Right{padding-right: 23%;} */
  .blog-all-page-search-text {
    font-size: 36px;
    line-height: 48px;
  }
  .pagination-list li {
    font-size: 1rem;
    padding: 0 0.7rem;
  }
  .margin-botm-span {
    margin-bottom: unset !important;
  }
  footer span {
    margin-bottom: 9px;
  }
  .footer.Right ul {
    justify-content: space-around;
  }
  footer .data {
    padding-left: 15px;
    padding-bottom: 30px;
    padding-top: 20px;
  }
  .footer.Left {
    padding-bottom: 1px;
  }
  footer p.bottomText {
    bottom: 8px;
    right: 20px;
  }
  .footer.Left span,
  .footer.Left a {
    padding-left: 11px;
  }
  footer .Right {
    padding-top: 8%;
  }
  Footer .Right li {
    margin-bottom: 18px;
  }
  footer .data p {
    font-size: 10px;
    line-height: 12px;
  }
}
@media (max-width: 770px) {
  footer .Right {
    padding-right: 9%;
    padding-left: 9%;
  }
  .footer.Left span,
  .footer.Left a {
    padding-left: 5px;
  }
}
.dot-pulse {
  display: flex;
}
.transmiting-data {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-submit-success-div {
  display: flex;
  justify-content: center;
}
.industries-we-serve-section {
  position: relative;
  /* z-index: -1; */
  overflow: hidden;
}
.industry-we-serve-gradient {
  position: absolute;
  width: 181px;
  height: 108px;
  left: 0;
  top: 0;

  background: rgba(255, 61, 0, 0.41);
  filter: blur(400px);
}
.home-page-background {
  padding-top: 2%;
}
.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}
.swiper-slide {
  transition: all 0.6s ease;
}
.swiper-slide-active {
  transform: scale(1.15) !important;
}
.carousel-card {
  /* padding: 21% 0; */
}
@media (max-width: 1500px) {
  .swiper-slide {
    width: 47.5% !important;
    margin: 0 26px;
  }
}
@media (max-width: 1200px) {
  .swiper-slide {
    width: 45.5% !important;
    margin: 0 26px;
  }
}

.tooltip-icon {
  /* position: absolute;
  right: 48%;
  padding-bottom: 7px; */
}
.tooltip-text-icon {
  /* position: absolute; */
  /* right: 35%; */
  /* padding-bottom: 7px; */
}
.tooltip-text-icon svg {
  position: absolute;
  right: 32%;
  bottom: 15px;
}
.inputBox {
  position: relative;
}
.tooltip-icon svg {
  position: absolute;
  right: 47%;
  bottom: 15px;
}

@media screen and (max-width: 767px) {
  .tooltip-icon {
    right: 13%;
  }
  .tooltip-text-icon {
    right: 13%;
  }
}
@media (max-width: 1200px) {
  .home-page-section-intro {
    height: unset;
  }
  .industries-we-serve-section {
    margin-top: 62px;
  }
}
@media (max-width: 900px) {
  .home-page-section-intro {
    height: unset;
  }
  .home-page-background {
    padding-top: 48px !important;
  }
  .scroll-down-text {
    top: 61%;
  }
  .scroll-down-text svg {
    margin-top: 0;
  }
  .home-page-background {
    height: unset;
    padding: 30rem 0;
  }
}
@media (max-width: 700px) {
  .join-email-input input {
    font-size: 14px;
    line-height: 17px;
  }
}
@media (max-width: 500px) {
  .home-page-section-intro {
    min-height: unset;
    margin-bottom: 70px;
  }
  .first-join-wait-list {
    margin-bottom: 75px;
  }
  .Featured-articles-blogopen h1,
  .blog-landing-feature-section h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .blog-landing-page-header-input {
    margin-bottom: 20px;
  }
  .blog-open-page-text h2 {
    font-size: 24px;
    margin: 50px 0 20px;
    text-align: center;
  }
  .Blog-open-page-text p {
    font-size: 16px;
  }
  .heading-part-blog-img img {
    height: 200px;
  }
}
@media (max-width: 800px) {
  /* .home-page-globe{top: 35%;} */
  .home-page-background::after {
    height: 143px;
  }
}
@media (max-width: 650px) {
  /* .home-page-globe{top: 35%;} */
}
.timeout-text {
  align-items: baseline;
}
.timeout-text span {
  white-space: nowrap;
}
.second-appearing-part-cmd .react-typewriter-text-wrap {
  display: inline;
}
@media (max-width: 500px) {
  .home-page-section-intro-img img {
    /* width: 73%; */
    margin-left: 0;
  }
}
.last-text-section span {
  color: #fff;
  opacity: 0.7;
}
.last-text-section p {
  padding-bottom: 21px;
}
.last-text-section h3 {
  font-weight: 300;
  font-size: 26px;
  line-height: 30px;
}
@media (max-width: 800px) {
  .last-text-section h3 {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
  }
}
@media (max-width: 500px) {
  .last-text-section h3 {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-top: 21px;
  }
  .why-too-decimal-container-follow-lines-heading img {
    margin-top: 1px;
  }
}
.hitEnter span {
  color: #05ff00;
  opacity: 1;
}

@media (max-width: 1200px) {
  .home-page-section-intro {
    height: unset;
  }
  .blogOpen-first-heading h1 {
    font-size: 45px;
    padding: 20px 0;
  }
  .blog-open-semi h2 {
    font-size: 1.5em;
    margin: 0.5em auto;
  }
}
@media (max-width: 900px) {
  .home-page-section-intro {
    height: unset;
  }
  .home-page-background {
    padding-top: 48px !important;
  }
  .scroll-down-text {
    top: 56%;
  }
  .scroll-down-text svg {
    margin-top: 0;
  }
  .home-page-background {
    height: unset;
    padding: 30rem 0;
  }
}
@media (max-width: 500px) {
  .home-page-section-intro {
    min-height: unset;
    margin-bottom: 70px;
  }
  .first-join-wait-list {
    margin-bottom: 75px;
  }
  .last-text-section p {
    padding-bottom: 0;
  }
  .last-text-section p.hitEnter {
    margin: 0;
  }
  .carousel-card {
    height: 200px;
  }
}

@media (max-width: 650px) {
  /* .home-page-section-intro-img-bg img{top: 257px;} */
}
@media only screen and (max-width: 1200px) {
  .home-page-section-intro-text {
    width: unset;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .tooltip-icon svg {
    right: 4%;
    bottom: 13px;
  }
  .tooltip-text-icon svg {
    right: 3%;
    bottom: 13px;
  }
  .scroll-down-text svg {
    display: none;
  }
  .scroll-down-text {
    top: 75%;
  }
}
@media (max-width: 600px) {
  .home-page-background {
    padding: 22.5rem 0;
  }
  .home-page-satelite img {
    right: unset;
    left: 110%;
  }
  .home-sun img {
    top: -93px;
  }
  .home-page-background::after {
    height: 93px;
    border-radius: 45%;
  }
}
@media (max-width: 500px) {
  .display-after-1200:nth-child(2) {
    margin-bottom: 45px;
  }
  .home-page-section-intro-img-bg img {
    width: 60%;
    margin-left: 0;
    left: 76px;
    top: 99px;
  }
  g-landing-page-header {
    overflow: hidden;
  }
  @media (max-width: 1000px) {
  }
  @media (max-width: 1200px) {
    .carousel-card {
      min-width: 490px;
    }

    .carousel-card p {
      font-size: 24px;
      padding-bottom: 0;
    }
    .carousel-text h1 {
      font-size: 36px;
    }
    .carosel-card-img {
      width: 100%;
    }
  }

  @media (max-width: 760px) {
    .carosel-card-img {
      width: 70%;
    }
  }
  @media (max-width: 600px) {
    .carousel-card p {
      font-size: 12px;
      line-height: 14px;
    }
    .carousel-card h1 {
      font-size: 18px;
      line-height: 22px;
    }
    .carousel-text {
      padding-left: 15px;
    }
    .carousel-card p {
      font-size: 13px;
    }
    .carosel-card-img {
      width: 100%;
      margin-left: -10px;
    }
    .carosel-card-img img {
      width: 92%;
    }
    .carousel-card {
      border-radius: 10px;
    }
    .carousel-card {
      min-width: 260px;
      padding: 6px;
    }
  }
  .bottom-why-text {
    padding-top: 0 !important;
    padding-bottom: 0;
    margin-top: 0;
  }
  .why-too-decimal-container-follow-lines-heading-bottom-part-content {
    padding-bottom: 15px;
  }
}
::-webkit-scrollbar {
  width: 6px !important;
  background-color: rgba(0, 0, 0, 0.0005) !important;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.0005) !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff !important;
  border-radius: 10px !important;
}
body {
  overflow: overlay !important;
}
.carousel-card:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.carousel-card3 .carousel-text p {
  color: #0f0f0f !important;
}
.carousel-card3 .carousel-text h1 {
  color: #0f0f0f !important;
  font-family: Lato;
}
@media (max-width: 1360px) {
  .carousel-text {
    width: 379px;
  }
  .carosel-card-img img {
    width: 70%;
  }
  .carousel-text p {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
  }
}
@media (max-width: 1100px) {
  .carousel-text {
    width: 329px;
  }
  .carosel-card-img img {
    width: 95%;
  }
  .carousel-text h1 {
    margin-top: 5px;
  }
  .carousel-card {
    min-width: 470px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 760px) {
  .carousel-new {
    height: 298px;
  }
  .carousel-card {
    padding: 17px;
    /* height: 150px; */
    margin: 0px 23px;
    padding-left: 8px;
  }
}
@media (max-width: 600px) {
  .second-appearing-part-cmd p {
    margin-bottom: 17px;
  }
  .color-dots {
    margin-bottom: 30px;
  }

  .carousel-card {
    margin: 0px 13px;
  }
  .carousel-new {
    height: 241px;
  }
  .carousel-new {
    margin-top: 0px;
  }
  .carousel-card {
    min-width: 260px;
    height: 160px;
    border-radius: 10px;
  }
  .carosel-card-img img {
    width: 85%;
  }
  .carousel-text p {
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 5px;
  }
  .carousel-text {
    width: 165px;
  }
  .bottom-why-text {
    font-weight: bold !important;
  }
  .timeout-text p {
    padding-top: 22px;
  }
  .last-text-section p {
    padding-top: 27px;
  }
  .last-text-section p:nth-child(1) {
    padding-top: 2px;
  }
  .join-email-input input {
    height: auto;
    padding: 15px 0 15px 22px;
    border-radius: 10px;
  }
  .transmit-btn button {
    border-radius: 10px;
    padding: 10px 15px;
    border-radius: 5px;
  }
  .pagination-list li {
    font-size: 1rem;
    padding: 0rem 0.5rem;
  }
  .blog-all-page-search-text {
    font-size: 24px;
    line-height: 36px;
  }
  .blog-landing-page-header-input input,
  .blog-all-input input {
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    border-radius: 10px;
    padding-left: 20px;
  }
  .blog-landing-page-header-input input::placeholder,
  .blog-all-input input::placeholder {
    font-size: 14px;
    line-height: 18px;
  }
  .blog-landing-page-recent h1 {
    margin: 0;
  }
}

.svg-style {
  display: none;
}

@media (min-width: 1024px) {
  .svg-style {
    display: block;
  }
  .industries-we-serve-section {
    margin-top: -164px;
  }
  .flex-whydecimal-wrap {
    padding-right: 40px;
  }
  .why-choose-too-decimal-container {
    margin: 0;
    max-height: 1455px;
  }
}

@media (max-width: 1440px) {
  .why-choose-too-decimal-container {
    max-height: 1485px;
  }
}
@media (max-width: 1420px) {
  .why-choose-too-decimal-container {
    max-height: 1500px;
  }
}
@media (max-width: 1282px) {
  .why-choose-too-decimal-container {
    max-height: 1550px;
  }
}

/**** Join waitlist card styles ****/

.join-waitlist-card-list {
  list-style-type: lower-greek;
  text-align: justify !important;
  /* list-style-position: outside; */
}

.join-waitlist-card-list-item {
  margin-bottom: 10px;
}

.join-waitlist-card-list-item span {
  line-height: 20px;
  position: relative;
  left: 10px;
  font-size: 18px;
  text-align: justify !important;
  color: #fff;
}
@media (max-width: 1030px) and (min-height: 1300px) {
  .home-page-globe canvas {
    width: 100% !important;
    height: unset !important;
    left: 0 !important;
    object-fit: contain;
  }
  .home-page-globe {
    width: 100%;
    height: unset;
    position: absolute;
    left: unset;
    top: 220px;
    z-index: 1;
  }
}
@media (max-width: 950px) {
  .home-page-globe canvas {
    width: 100% !important;
    height: unset !important;
    left: 0 !important;
    object-fit: contain;
  }
  .home-page-globe {
    width: 100%;
    height: unset;
    position: absolute;
    left: unset;
    top: 220px;
    z-index: 1;
  }
}
@media (max-width: 1470px) and (min-width: 1370px) {
  .why-choose-too-decimal-container {
    max-height: 1527px;
  }
}
@media (max-width: 1370px) and (min-width: 1240px) {
  .why-choose-too-decimal-container {
    max-height: 1537px;
  }
}
@media (max-width: 1240px) and (min-width: 1200px) {
  .why-choose-too-decimal-container {
    max-height: 1576px;
  }
}
@media (max-width: 1200px) {
  .why-choose-too-decimal-container {
    max-height: 1294px;
  }
}

@media (max-width: 1150px) {
  .why-choose-too-decimal-container {
    max-height: 1338px;
  }
}
@media (max-width: 1031px) {
  .why-choose-too-decimal-container {
    max-height: 1389px;
  }
}
@media (max-width: 1023px) {
  .why-choose-too-decimal-container {
    max-height: unset;
  }
}
/* @media (max-width:775px) and (min-width:740px) {.why-choose-too-decimal-container {
    max-height: 1338px;
  }}
  @media (max-width:740px) and (min-width:650px) {.why-choose-too-decimal-container {
    max-height: 1558px;
  }} */

@media (max-width: 775px) and (min-width: 550px) {
  .text-under-dir .why-too-decimal-container-follow-lines-heading img {
    margin-top: -37px;
  }
  .why-too-decimal-container-follow-lines-heading-bottom-part img {
    margin-top: -37px;
  }
}
@media (max-width: 600px) {
  .transmit-btn button {
    width: unset;
    height: unset;
    margin-top: 5px;
  }
}
@media (max-width: 500px) {
  .transmit-btn button {
    width: unset;
    height: unset;
    margin-top: 7px;
  }

  .text-under-dir .why-too-decimal-container-follow-lines-heading img {
    margin-top: -23px;
  }
  /* .why-too-decimal-container-follow-lines-heading-bottom-part img{margin-top: -37px;} */
}
@media (max-width: 450px) {
  /* .text-under-dir .why-too-decimal-container-follow-lines-heading img{margin-top: -23px;} */
  .why-too-decimal-container-follow-lines-heading-bottom-part img {
    margin-top: -23px;
  }
}
/* body{position: relative;}
footer{position: relative;bottom: 0%;} */
@media (max-width: 1400px) {
  @keyframes autoscroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-745px * 10));
    }
  }
}
@media (max-width: 1100px) {
  @keyframes autoscroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-427px * 10));
    }
  }
}
@media (max-width: 760px) {
  .carousel-track {
    width: calc(270px * 10);
    animation: autoscroll 25s linear infinite;
  }
  @keyframes autoscroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-427px * 10));
    }
  }
}
@media (max-width: 600px) {
  .carousel-track {
    width: calc(270px * 10);
    animation: autoscroll 25s linear infinite;
  }
  @keyframes autoscroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-230px * 10));
    }
  }
}
.why-choose-too-decimal-container {
  margin: 15% 0;
}
@media (max-width: 1400px) {
  .why-choose-too-decimal-container {
    margin: 13% 0;
  }
}
@media (max-width: 1280px) {
  .why-choose-too-decimal-container {
    margin: 10% 0;
  }
}
@media (max-width: 1250px) {
  .why-choose-too-decimal-container {
    margin: 8% 0;
  }
}
@media (max-width: 1200px) {
  .why-choose-too-decimal-container {
    margin: 7% 0;
  }
}
@media (max-width: 1160px) {
  .why-choose-too-decimal-container {
    margin: 5% 0;
  }
}
@media (max-width: 1130px) {
  .why-choose-too-decimal-container {
    margin: 4% 0;
  }
}
@media (max-width: 1090px) {
  .why-choose-too-decimal-container {
    margin: 2% 0;
  }
}
@media (max-width: 1024px) {
  .why-choose-too-decimal-container {
    margin: 2% 0;
  }
}
